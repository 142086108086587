import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useAuth0 } from "@auth0/auth0-react";
import { handleFetchErrors, httpHeader, getApiUrl, getBaseUrl } from '../common/utils';
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { useStyles } from '../common/styles';
import i18n from '../common/i18n';

export default function MyAccount() {
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    //const contract = '';

    const noContract = { id: 0, contractNumber: t('None'), hrPortalDefault: false };
    const [contract, setContract] = React.useState('0');
    const [language, setLanguage] = React.useState(i18n.language);
    const [contracts, setContracts] = React.useState([noContract]);
    const [open, setOpen] = React.useState(false);
    const [openSendReset, setOpenSendReset] = React.useState(false);

    const handleChange = React.useCallback(async (event) => {
        setOpen(false);
        if (isAuthenticated && event.target.value > 0) {
            getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {

                var httpCmd = httpHeader('PATCH', token);

                fetch(getApiUrl((window as any).Config, 'hrportal') + '/currentemployee/contracts?defaultcontractid=' + event.target.value, httpCmd)
                    .then(res => handleFetchErrors(res).json())
                    .then(ret =>
                        updateContracts(ret)
                    )
                    .catch(e => setOpen(true));
            }).catch(e => setOpen(true));
        }
        //setContract(event.target.value as string);
    }, [isAuthenticated]);

    const handleChangeLanguage = React.useCallback(async (event) => {
        //console.log(i18n.language);
        //console.log(event.target.value);
        localStorage.setItem('i18nextLng', event.target.value);
        window.location.reload();
    }, [isAuthenticated]);

    const updateContracts = (ret: any) => {
        //console.log(ret);
        var selected = ret.contracts.filter((c: any) => c.hrPortalDefault);

        setContracts(a => [...a.filter(a => a.id !== 0), noContract]);
        if (selected.length === 0)
            ret.contracts.unshift(noContract);

        //console.log(ret);
        if (Array.isArray(selected) && selected.length > 0) {
            setContract(selected[0].id);
            if (ret.contracts[0].id === 0)
                ret.contracts.shift();
            setContracts(ret.contracts);
        }
        else {
            setContracts(ret.contracts);
        }
    }

    const sendMailResetPassword = async() => {
        if (isAuthenticated) {
            setOpenSendReset(false);
            var auth0Dom = 'https://' + (window as any).Config.Auth0TenantHost;
            var email = ''
            var httpCmd;
            try {

                await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {

                    httpCmd = httpHeader('GET', token);

                });

                await fetch(auth0Dom + '/userinfo', httpCmd)
                    .then(res => handleFetchErrors(res).json())
                    .then(ret => {
                        email = ret.email
                    });

                const req = {
                    crossDomain: true,
                    method: 'POST',
                    body: JSON.stringify({
                        client_id: (window as any).Config.Auth0ClientId,
                        email: email,
                        connection: 'Username-Password-Authentication'
                    }),
                    headers: {
                        'content-type': 'application/json',
                    },
                }

                const url = auth0Dom + '/dbconnections/change_password';

                await fetch(url, req as any);
                setOpenSendReset(true);
            }
            catch (e) {
                console.log(e)
            }
        }
    }

    React.useEffect(() => {//when logged in
        if (isAuthenticated) {
            getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {

                var httpCmd = httpHeader('GET', token);

                fetch(getApiUrl((window as any).Config, 'hrportal') + '/currentemployee/contracts', httpCmd)
                    .then(res => handleFetchErrors(res).json())
                    .then(ret =>
                        updateContracts(ret)
                    )
                    .catch(e => setOpen(true));
            }).catch(e => setOpen(true));
        }
        else
            loginWithRedirect({ ui_locales: i18n.language, redirectUri: window.location.origin + process.env.PUBLIC_URL });
    }, [isAuthenticated]);

    return (
        <Container maxWidth="sm" className={classes.centeredTopContainer}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography><FontAwesomeIcon icon="user" size="2x" fixedWidth className={classes.icons} /><span className={classes.pageTitleText}>{t("MyAccount")}</span></Typography>
                    <Divider className={classes.pageTitleDivider} />
                    <Typography variant="h6" className={classes.subTitle}>{t("MyProfile")}</Typography>
                    <div className={classes.linkOption}><Link onClick={async () => { await sendMailResetPassword() }}>{t("ChangePassword")}</Link></div>
                    <div className={classes.linkOption}><Link onClick={() => { history.push(getBaseUrl() + "/myemployeefile"); }}>{t("MyEmployeeFile")}</Link></div>
                    <Typography variant="h6" className={classes.subTitle}>{t("Preferences")}</Typography>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="contract-select-outlined-label" >{t("DefaultContract")}</InputLabel>
                        <Select
                            labelId="contract-select-outlined-label"
                            id="contract-select-select-outlined"
                            value={contract}
                            onChange={handleChange}
                            label={t("DefaultContract")}
                            displayEmpty
                        >
                            {
                                contracts.map((ctr: any) => {
                                    if ('id' in ctr && 'contractNumber' in ctr)
                                        return (<MenuItem key={ctr.id} value={ctr.id}>{ctr.contractNumber}</MenuItem>);
                                    else
                                        return null;
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="contract-select-outlined-label" >{t("LanguageItself")}</InputLabel>
                        <Select
                            labelId="language-select-outlined-label"
                            id="language-select-select-outlined"
                            value={language}
                            onChange={handleChangeLanguage}
                            label={t("LanguageItself")}
                            displayEmpty
                        >
                            <MenuItem key="fr-FR" value="fr">Français</MenuItem>
                            <MenuItem key="en-US" value="en">English</MenuItem>
                        </Select>
                    </FormControl>
                    <Collapse in={open}>
                        <Alert severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {t("ErrorRetry")}
                        </Alert>
                    </Collapse>
                    <Collapse in={openSendReset}>
                        <Alert severity="success"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenSendReset(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {t("SuccessSendPasswordReq")}
                        </Alert>
                    </Collapse>
                </CardContent>
            </Card>
        </Container>
    );
}
