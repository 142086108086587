import * as React from 'react';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { Badge, CardContent, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../common/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { SessionContext } from '../../App';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DoneIcon from '@material-ui/icons/Done';


export default function AbsenceRequestTile() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { isAuthenticated } = useAuth0();
    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;
    const [echoData, setEchoData] = React.useState({ PendingAbsenceRequests: 0, PendingAbsenceValidation: 0 });

    React.useEffect(() => {//when logged in
        setEchoData({ PendingAbsenceRequests: state.PendingAbsenceRequests, PendingAbsenceValidation: state.PendingAbsenceValidation });
    }, [isAuthenticated, state, state.PendingAbsenceRequests, state.PendingAbsenceValidation]);

    return (<Card className={classes.tileCard} onClick={() => { history.push(process.env.PUBLIC_URL + "/absences"); }}>
        <CardContent>
            <Typography className={classes.tileTitle}><FontAwesomeIcon icon="umbrella-beach" size="2x" fixedWidth className={classes.icons} /><span className={classes.tileTitleText}>{t("TimeOff")}</span></Typography>
            <Divider />
            <table style={{ paddingTop: '15px' }}><tbody>
                <tr onClick={(event) => { event.stopPropagation(); history.push(process.env.PUBLIC_URL + "/absences/newabsencerequest"); }}>
                    <td style={{ paddingTop: '10px' }}  className={classes.tileElement}>{t("AbsenceNewRequest")}</td>
                </tr>
                <tr onClick={(event) => { event.stopPropagation(); history.push(process.env.PUBLIC_URL + "/absences/currentabsencerequests"); }}>
                    <td style={{ paddingTop: '10px' }} className={classes.tileElement} >{t("AbsenceCurrent")}</td>
                    <td style={{ verticalAlign: 'bottom', paddingLeft: '20px' }} className={classes.tileElement}>
                        <Badge color="primary" badgeContent={echoData.PendingAbsenceRequests}>
                            {(echoData.PendingAbsenceRequests > 0) && (<FontAwesomeIcon icon="calendar-check" size="sm" fixedWidth pull="right" />)}
                            {(echoData.PendingAbsenceRequests === 0) && (<DoneIcon />)}
                        </Badge>
                    </td>
                </tr>
                {
                    (state.IsValidateManager) &&
                    (
                        <tr onClick={(event) => { event.stopPropagation(); history.push(process.env.PUBLIC_URL + "/absencesvalidate/validatableabsencerequests"); }}>
                            <td style={{ paddingTop: '10px' }} className={classes.tileElement}>{t("RequestToValidate")}</td>
                            <td style={{ verticalAlign: 'bottom', paddingLeft: '20px'}} className={classes.tileElement}>
                                <Badge color="secondary" badgeContent={echoData.PendingAbsenceValidation}>
                                    {(echoData.PendingAbsenceValidation > 0) && (<NotificationsIcon />)}
                                    {(echoData.PendingAbsenceValidation === 0) && (<DoneIcon />)}
                                </Badge>
                            </td>
                        </tr>
                    )
                }
            </tbody></table>
        </CardContent>
    </Card>
    );
}