import * as React from 'react';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { CardContent, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../common/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { httpHeader, handleFetchErrors, getApiUrl } from '../common/utils';
import { SessionContext } from './../App';


export default function TimePeriodBalanceTile() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [balances, setBalances] = React.useState([]);
    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;
    
    React.useEffect(() => {//when logged in
        const fetchInfos = async () => {
            if (isAuthenticated && state.AddressId && state.AddressId > 0) {
                var httpCmd;
                await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {

                    httpCmd = httpHeader('GET', token);

                }).catch(e => console.log(e));

                await fetch(getApiUrl((window as any).Config, 'hrportal') + '/timeperiodbalance?selection=summaryview&addressid=' + state.AddressId + '&contractid=' + state.ContractId, httpCmd)
                    .then(res => handleFetchErrors(res).json())
                    .then(ret => {
                        //console.log(ret);

                        setBalances(ret.balances);
                    }).catch(e => console.log(e));
            };
        }
        fetchInfos();
    }, [isAuthenticated, state]);


    return (<Card className={classes.tileCard} onClick={() => { history.push(process.env.PUBLIC_URL + "/timebalance"); }}>
        <CardContent>
            <Typography className={classes.tileTitle}><FontAwesomeIcon icon="user-clock" size="2x" fixedWidth className={classes.icons} /><span className={classes.tileTitleText}>{t("HoursBalance")}</span></Typography>
            <Divider />
            <table style={{ paddingTop: '15px' }}><tbody>
                {
                    (balances) && (
                        balances.map((bal: any) => {
                            return (<tr key={bal.id} ><td className={classes.tileElement}>{bal.code} - {bal.text}</td><td>&nbsp;&nbsp;&nbsp;</td><td className={classes.tileElement}><b>{bal.balance}</b></td></tr>);
                        }))
                }
            </tbody></table>
        </CardContent>
    </Card>
    );
}