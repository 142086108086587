import {
    GetTokenSilentlyOptions
} from '@auth0/auth0-spa-js';

import i18n from '../common/i18n';

export const httpHeader = function (command: string, jwtAccessToken: string) {
    return {
        //crossDomain: true,
        method: command,
        body: null,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwtAccessToken
        },
    }
}

export const handleFetchErrors = function (response: any) {
    if (!response.ok) {
        if (response.status === 401) {
            window.location.href = getBaseUrl() + '/';//alert('session échue !');
        }

        throw Error(response.statusText);
    }
    return response;
}

export const getApiUrl = function (config: any, module: string) {
    return config.ApiBaseUrl + 'v1/' + config.SubPath + '/' + module;
}

export const getBaseUrl = function () {
    return process.env.PUBLIC_URL;
}

export const detectNative = function () {
    const toMatch = [
        /Android/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export const getIconByFileName = function (filename: string) {

    var filepart = filename.split('.')
    var fileext = '';
    if (filepart.length > 0)
        fileext = filepart[filepart.length - 1];

    var selected = [];

    if (fileext.length > 0)
        selected = (window as any).Config.ExtensionsIcons.filter((c: any) => c.Extension === '.' + fileext.toLowerCase());

    if (Array.isArray(selected) && selected.length === 0)
        selected = (window as any).Config.ExtensionsIcons.filter((c: any) => c.Extension === ''); //default

    if (Array.isArray(selected) && selected.length > 0)
        return selected[0].Icon;
}

export const delay = function (ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const downloadDocument = async function (fileGuid: string, url: string, windowOpen: any, getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>) {
    var httpCmd;

    await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
        httpCmd = httpHeader('GET', token);
    }).catch(e => console.log(e));

    await fetch(getApiUrl((window as any).Config, 'hrportal') + url, httpCmd)
        .then(res => handleFetchErrors(res).blob())
        .then(blob => {
            var file = window.URL.createObjectURL(blob);

            if (windowOpen) { //Pour bizarrerie Safari 25.08.21 / JMA Ticket 154222
                //console.log("isSafari");
                windowOpen.location = file;
            }
            else {
                //console.log("not isSafari");
                window.open(file, '_blank');
            }
        }).catch(e => console.log(e));
}

/**
 * fetch the openid configuration for the issuer
 * @returns {Promise<any>}
 */
export const getConfig = async function (domain: string) {
    const response = await fetch(`https://${domain}/.well-known/openid-configuration`);
    return response.json();
}

export const emptySession = function (): SessionState {
    //Create a new each time instead of using a constant because of : https://stackoverflow.com/questions/57838862/component-not-re-rendering-when-value-from-usecontext-is-updated
    let value = {};
    value = {
        AddressId: 0, ContractId: 0, FirstName: '', LastName: '', EmployeeNo: '', PendingAbsenceRequests: 0, PendingAbsenceValidation: 0, IsAbsenceEnabled: false, IsValidateManager: false
    }
    return (value as SessionState);
}

export const convertToAuth0Language = function (lang: string) : string {
    var lst = new Object() as any;
    lst = {
        "fr": "fr-FR", 
        "en": "en"
    }
    if (lang.length === 0)
        lang = i18n.language;

    var toSearch = lang.substr(0, 2).toLowerCase();

    if (toSearch in lst)
        return lst[toSearch];
    else
        return "fr-FR";//default of the default
}

export const checkModuleActive = function (name: string): boolean {
    if ((window as any).Config) {
        if ((window as any).Config.Modules) {
            if (name in (window as any).Config.Modules) {
                if ('Active' in (window as any).Config.Modules[name]) {
                    return Boolean((window as any).Config.Modules[name].Active);
                }
                else
                    console.log("window.Config.Modules." + name + ".Active not found");
            }
            else
                console.log("window.Config.Modules." + name + " not found");
        }
        else
            console.log("window.Config.Modules not found");
    }
    else
        console.log("window.Config not found");
    return true;
}

export const getAppName = function(): string {
    if ((window as any).Config) {
        if ((window as any).Config.AppName) {
            if (typeof (window as any).Config.AppName === 'string')
                return (window as any).Config.AppName;
            else
                if (i18n.language in (window as any).Config.AppName) {
                    return (window as any).Config.AppName[i18n.language];
                }
                else
                    if ((window as any).Config.DefaultLanguage && (window as any).Config.DefaultLanguage in (window as any).Config.AppName)
                        return (window as any).Config.AppName[(window as any).Config.DefaultLanguage];
                    else
                        throw new Error('window.Config.AppName doesn\'t even contain description for default language, please fix your config file.');
        }
        else
            throw new Error("window.Config.AppName not found");
    }
    else
        throw new Error("window.Config not found");
}
