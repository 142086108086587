import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    gridForm: {
        width: '100%',
        margin: 0,
    },
    tileCard: {
        minWidth: 100,
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.grey[100]
        },
        height: '98%',
        [theme.breakpoints.up('sm')]: {
            width: '98%',
        }
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 0,
    },
    homeTile: {
        height: 250,
        cursor: 'pointer'
    },
    tileTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        color: theme.palette.text.primary
    },
    tileTitleText: {
        fontSize: '1.4em',
        marginLeft: '12px',
    },
    tileElement: {
        fontSize: '1.2em',
        verticalAlign: 'center'
    },
    pageTitleText: {
        fontSize: '1.5em',
        marginLeft: '12px',
    },
    icons: {
        color: theme.palette.text.primary
    },
    topContainer: {
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            padding: 0
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    pageTitleDivider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    subTitle: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    linkOption: {
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    card: {
        minWidth: 100,
        display: 'flex',
        flexFlow: 'column',
        paddingRight: '10px',
        [theme.breakpoints.up('sm')]: {
            width: '98%',
            height: '98%',
        },
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            height: '100%',
        }
    },
    centeredTopContainer: {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            padding: 0
        }
    }, 
    indent: {
        marginLeft: theme.spacing(2),
    },
    indent2: {
        marginLeft: theme.spacing(4),
    },
    liHover: {
        '&:hover': {
            textDecoration:'underline'
        }
    },
    flexGrow: {
        flexGrow: 1,
    }
  }), { index: 1 }  //  optional argument for JSS, to set position after MUI stylesheets
);