import * as React from 'react';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { CardContent, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../common/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { httpHeader, handleFetchErrors, getApiUrl, getIconByFileName, downloadDocument, convertToAuth0Language, checkModuleActive } from '../common/utils';
import { SessionContext } from './../App';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import * as rdd from 'react-device-detect';

export default function SalaryDocument() {
    const QTY_BY_PAGE = 20;

    const { t } = useTranslation();
    const classes = useStyles();
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [documents, setDocuments] = React.useState([]);
    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;

    const noContract = { id: 0, contractNumber: t('None'), hrPortalDefault: false };
    //const [contract, setContract] = React.useState('0');
    const [contracts, setContracts] = React.useState([noContract]);
    //const [type, setType] = React.useState('0');
    //const [year, setYear] = React.useState('0');
    const [years, setYears] = React.useState([0] as any[]);
    //const [year, setYear] = React.useState('0');
    const [periods, setPeriods] = React.useState([0] as any[]);

    const initialQueryState = { url: '', pageNo: 0, allLoad: false };

    const [query, setQuery] = React.useState(initialQueryState);
    const [isSearching, setIsSearching] = React.useState(false);

    const [formData, setFormData] = React.useState({
        type: '0',
        year: '0',
        period: '0',
        contract: '0'
    });

    window.onscroll = () => {
        if (document.documentElement.scrollHeight - (document.documentElement.offsetHeight / 2) <= document.documentElement.scrollTop + document.documentElement.offsetHeight) {
            loadNextPage({ ...query, pageNo: query.pageNo + 1 });
        }
    }

    async function fetchContract() {
        if (isAuthenticated && state.AddressId && state.AddressId > 0) {
            setDocuments([]);

            let httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {

                httpCmd = httpHeader('GET', token);
            }).catch(e => console.log(e));

            await fetch(getApiUrl((window as any).Config, 'hrportal') + '/currentemployee/contracts', httpCmd)
                .then(res => handleFetchErrors(res).json())
                .then(ret => {
                    if (ret.contracts) {
                        if (ret.contracts.length === 0)
                            ret.contracts.unshift({ id: null, addressId: 0, contractNumber: '', hrPortalDefault: false });

                        setContracts(ret.contracts);

                        var selected = ret.contracts.filter((c: any) => c.hrPortalDefault);
                        if (Array.isArray(selected) && selected.length > 0)
                            setFormData(inputs => ({ ...inputs, ['contract']: selected[0].id }));
                        else
                            setFormData(inputs => ({ ...inputs, ['contract']: ret.contracts[0].id }));
                    }
                })
                .catch(e => console.log(e));
        }
    }

    function getContractId() {
        return parseInt(formData.contract) === 0 ? state.ContractId : parseInt(formData.contract);
    }

    async function fetchPossibleValues() {
        if (isAuthenticated && state.AddressId && state.AddressId > 0) {
            var ctrId = getContractId();

            let httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('GET', token);
            }).catch(e => console.log(e));

            await fetch(getApiUrl((window as any).Config, 'hrportal') + '/salarydocument?addressid=' + state.AddressId + '&contractid=' + ctrId + '&pageno=0&pagesize=2147483647', httpCmd)
                .then(res => handleFetchErrors(res).json())
                .then(ret => {
                    var retYears = [...ret.documents];
                    var retPeriods = [...ret.documents]; 

                    retYears = retYears.map((year: any) => year.year).filter((value: any, index: any, self: any) => self.indexOf(value) === index)
                    retPeriods = retPeriods.map((period: any) => period.period).filter((value: any, index: any, self: any) => self.indexOf(value) === index).sort(function (a, b) { return a - b }); //.map(a => { let rObj: any = {}; rObj.period = a; return rObj });

                    if (!Array.isArray(retYears))
                        retYears = [];

                    retYears.unshift(0);

                    setYears(retYears);

                    if (!Array.isArray(retPeriods))
                        retPeriods = [];

                    retPeriods.unshift(0);

                    if (retPeriods.indexOf(null) > 0)
                        retPeriods.splice(retPeriods.indexOf(null), 1);

                    setPeriods(retPeriods);
                }).catch(e => console.log(e));
        }
    }

    const loadNextPage = async (newQuery: any) => {
        if (isSearching) return;
        if (newQuery.allLoad) return;

        setQuery(newQuery);
        try {

            setIsSearching(true);
            var httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('GET', token);
            }).catch(e => console.log(e));

            try {
                var ctrId = getContractId();

                var qryUrl = getApiUrl((window as any).Config, 'hrportal') + '/salarydocument?addressid=' + state.AddressId + '&contractid=' + ctrId + '&pageno=' + newQuery.pageNo + '&pagesize=' + QTY_BY_PAGE;
                if (parseInt(formData.type) > 0) {
                    qryUrl += '&type=';

                    if (parseInt(formData.type) === 1)
                        qryUrl += 'payslip';
                    else
                        qryUrl += 'salarystatement';
                }
                if (parseInt(formData.year) > 0) 
                    qryUrl += '&year=' + formData.year;

                if (parseInt(formData.period) > 0)
                    qryUrl += '&period=' + formData.period;
                
                var res = await fetch(qryUrl, httpCmd);

                var text = await handleFetchErrors(res).text();
                //console.log(text);

                if (text.length > 0) {
                    var json = JSON.parse(text);

                    //console.log('before set');
                    if (newQuery.pageNo === 0)
                        setDocuments(json.documents);
                    else
                        setDocuments(documents.concat(json.documents));
                }
                else {
                    if (newQuery.pageNo === 0)
                        setDocuments([])
                    await setQuery(query => ({ ...query, allLoad: true })); //tout est loadé !
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        finally {
            setIsSearching(false);
        };
    }

    React.useEffect(() => {//when logged in
        if (isAuthenticated) {
            const fetchInfos = async () => {
                await fetchContract();
                await fetchPossibleValues();
                await loadNextPage(initialQueryState);
            };
            fetchInfos();
        }
        else
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });

    }, [isAuthenticated, state]);

    const downloadSalaryDoc = async function (fileGuid: string, docType: number, docContract: number) {
        var type = '';
        if (docType === 1)
            type = 'payslip';
        else
            type = 'salarystatement';

        await downloadDocument(
            fileGuid,
            '/salarydocument/' + fileGuid + '?type=' + type + '&addressid=' + state.AddressId + '&contractid=' + docContract,
            rdd.isSafari ? window.open() : null, //Pour bizarrerie Safari 25.08.21 / JMA Ticket 154222
            getAccessTokenSilently
        ).catch(e => { alert(t('ErrorLoad')) });
    }


    const refresh = async () => {
        await loadNextPage(initialQueryState);
    }

    const handleSelectChange = (event: React.FormEvent<any>) => { //HTMLInputElement | HTMLTextAreaElement
        if (typeof event.target !== 'undefined' && event.target) {
            //persist the info before calling an async fct.
            const target = (event.target as any);
            if (typeof target.value !== 'undefined' && target.value && typeof target.name !== 'undefined' && target.name) {
                const value = target.value;
                const name = target.name;
                setFormData(inputs => ({ ...inputs, [name]: value }));
            }
        }
    };

    if (!checkModuleActive('SalaryDocuments')) //Hidden module in config
        return (<></>);
    
    return (
        <Container maxWidth="md" className={classes.centeredTopContainer}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography><FontAwesomeIcon icon="search-dollar" size="2x" fixedWidth className={classes.icons} /><span className={classes.pageTitleText}>{t("SalaryDocument")}</span></Typography>
                    <Divider className={classes.pageTitleDivider} />
                    <div style={{ marginBottom: '20px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} lg={6} md={6} xl={4} >
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="contract-select-outlined-label" >{t("Contract")}</InputLabel>
                                    <Select
                                        labelId="contract-select-outlined-label"
                                        id="contract-select-select-outlined"
                                        name="contract"
                                        value={formData.contract}
                                        onChange={handleSelectChange}
                                        label={t("Contract")}
                                        displayEmpty
                                    >
                                        {
                                            contracts.map((ctr: any) => {
                                                if ('id' in ctr && 'contractNumber' in ctr)
                                                    return (<MenuItem key={ctr.id} value={ctr.id}>{ctr.contractNumber}</MenuItem>);
                                                else
                                                    return null;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6} md={6} xl={2} >
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="type-select-outlined-label" >{t("Type")}</InputLabel>
                                    <Select
                                        labelId="type-select-outlined-label"
                                        id="type-select-select-outlined"
                                        name="type"
                                        value={formData.type}
                                        onChange={handleSelectChange}
                                        label={t("Type")}
                                        displayEmpty
                                    >
                                        <MenuItem key="0" value="0">{t("All")}</MenuItem>
                                        <MenuItem key="1" value="1">{t("Salary")}</MenuItem>
                                        <MenuItem key="2" value="2">{t("Certificate")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6} md={6} xl={2}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="year-select-outlined-label" >{t("Year")}</InputLabel>
                                    <Select
                                        labelId="year-select-outlined-label"
                                        id="year-select-select-outlined"
                                        name="year"
                                        value={formData.year}
                                        onChange={handleSelectChange}
                                        label={t("Year")}
                                        displayEmpty
                                    >
                                        {
                                            years.map((year: string) => {
                                                if (parseInt(year) === 0)
                                                    return (<MenuItem key={String(year)} value={String(year)}>{t("All")}</MenuItem>);
                                                else
                                                    return (<MenuItem key={String(year)} value={String(year)}>{String(year)}</MenuItem>);
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6} md={6} xl={2}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="period-select-outlined-label" >{t("Period")}</InputLabel>
                                    <Select
                                        labelId="period-select-outlined-label"
                                        id="period-select-select-outlined"
                                        name="period"
                                        value={formData.period}
                                        onChange={handleSelectChange}
                                        label={t("Period")}
                                        displayEmpty
                                    >
                                        {
                                            periods.map((period: string) => {
                                                if (parseInt(period) === 0)
                                                    return (<MenuItem key={String(period)} value={String(period)}>{t("All")}</MenuItem>);
                                                else
                                                    return (<MenuItem key={String(period)} value={String(period)}>{String(period)}</MenuItem>);
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} md={12} xl={2} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', verticalAlign: 'center' }}>
                                <Button variant="contained" color="default" size="medium"
                                    onClick={() => { refresh(); }}>
                                    {t("Refresh")}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    {
                        documents.map((doc: any) => {
                            if ('title' in doc)
                                return (<Grid container style={{ width: '100%' }} key={doc.guid} className={classes.tileCard} onClick={() => { downloadSalaryDoc(doc.guid, doc.type, getContractId()) }}>
                                    <Grid item xs={2} sm={2} lg={1}>
                                        <FontAwesomeIcon icon={getIconByFileName(doc.fileName)} size="2x" fixedWidth title={doc.fileName} />
                                    </Grid>
                                    <Grid item xs={10} sm={10} lg={11}><Typography variant="h6">{doc.title}</Typography>
                                        <Typography className={classes.title}>{doc.description}</Typography>
                                    </Grid>
                                </Grid>);
                            else
                                return null;
                        })
                    }
                    {(document.documentElement.scrollHeight - 50 <= document.documentElement.clientHeight) && !query.allLoad && documents.length % QTY_BY_PAGE === 0 && (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><Button variant="contained" color="default" size="medium" onClick={() => { loadNextPage({ ...query, pageNo: query.pageNo + 1 }); }}>
                            {t("Loading")}
                        </Button></div>
                    )}
                </CardContent>
            </Card>
        </Container>
    );
}