import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../common/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { httpHeader, handleFetchErrors, getApiUrl, convertToAuth0Language, checkModuleActive } from '../../common/utils';
import { SessionContext } from './../../App';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CurrentAbsenceRequests(props: any) {
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const classes = useStyles();
    const { t } = useTranslation();

    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;

    const [isSearching, setIsSearching] = React.useState(false);
    const [absences, setAbsences] = React.useState([]);
    const [errorSendForm, setErrorSendForm] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);

    const [lastId, setLastId] = React.useState('');

    async function loadAll() {
        if (isSearching) return;

        try {
            await setAbsences([] as any);
            await setIsSearching(true);
            var httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('GET', token);
            }).catch(e => console.log(e));

            try {
                var res = await fetch(getApiUrl((window as any).Config, 'hrportal') + '/absence/currentrequests?addressid=' + state.AddressId + '&contractid=' + props.ContractId, httpCmd)

                var text = await handleFetchErrors(res).text();
                //console.log(text);

                if (text.length > 0) {
                    var json = JSON.parse(text);
                    //console.log(json);
                    await setAbsences(json.absenceRequests);
                    //console.log(absences);

                }
            }
            catch (e) {
                console.log(e);
            }
        }
        finally {
            setIsSearching(false);
        };
    }

    const sendRequest = async (id: string) => {
        try {
            let httpCmd: RequestInit | null = null;
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('DELETE', token);
            });

            if (httpCmd) {
                var res = await fetch(getApiUrl((window as any).Config, 'hrportal') + '/absence/request?addressid=' + state.AddressId + '&contractid=' + props.ContractId + '&id=' + id, httpCmd);

                var text = await handleFetchErrors(res).text();
                //console.log(text);

                if (text.length > 0) {
                    console.log(text)
                }
            }
            setErrorSendForm(false);
            loadAll();
            //console.log(formData);
        }
        catch (e) {
            setErrorSendForm(true);
            console.log(e);
        }
    }

    const handleClickOpen = (id: string) => {
        setErrorSendForm(false);
        setLastId(id);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleAccept = async () => {
        await sendRequest(lastId);
        setOpenDialog(false);
        //window.location.reload();
        loadAll();
    };

    React.useEffect(() => {//when logged in
        if (!isAuthenticated)
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
        else
            if (state.AddressId && state.AddressId > 0) {
                loadAll();
            }
    }, [isAuthenticated, props.ContractId, state.AddressId]);

    if (!checkModuleActive('Absences')) //Hidden module in config
        return (<></>);

    return (
        <React.Fragment>
            <Collapse in={errorSendForm}>
                <Alert severity="error">
                    {t("FromSendError")}
                </Alert>
            </Collapse>
            {
                absences.map((abs: any) => {
                    var dateStart = new Date(abs.absenceRequest.dateFrom)
                    var dateEnd = new Date(abs.absenceRequest.dateTo)
                    var dateRequest = new Date(abs.valueDate)

                    var iconCollReqStatus = ''
                    var iconNameReqStatus = ''
                    var iconColorReqStatus = ''
                    var iconHint = ''

                    switch (abs.requestStatus) {
                        case 2: //beingvalidated
                            iconNameReqStatus = 'hourglass-half';
                            iconColorReqStatus = '#FF6000';
                            iconHint = t("AbsenceRequestBeingValidated")
                            iconCollReqStatus = 'fas'
                            break;
                        case 3: //processed
                            if (abs.decision === 2) {
                                iconNameReqStatus = 'check-circle';
                                iconColorReqStatus = 'darkgreen';
                                iconHint = t("AbsenceRequestValidated")
                                iconCollReqStatus = 'far'
                            }
                            else {
                                iconNameReqStatus = 'times-circle';
                                iconColorReqStatus = 'red';
                                iconHint = t("AbsenceRequestDenied")
                                iconCollReqStatus = 'far'
                            }
                            break;
                        default: //undefined or transmitted
                            iconNameReqStatus = 'share-square';
                            iconColorReqStatus = 'blue';
                            iconHint = t("AbsenceRequestSended")
                            iconCollReqStatus = 'far'
                            break;
                    }

                    var period = '';
                    switch (abs.absenceRequest.period) {
                        case 2:
                            period = t("Morning");
                            break;
                        case 3:
                            period = t("Afternoon");
                            break;
                        case 4:
                            var timeStart = new Date(abs.absenceRequest.timeFrom)
                            var timeEnd = new Date(abs.absenceRequest.timeTo)

                            period = t("TimeSlot") + ' ' + t("From") + ' ' + timeStart.getHours() + ':' + timeStart.getMinutes().toString().padStart(2, '0') + ' ' + t("Until") + ' ' + timeEnd.getHours() + ':' + timeEnd.getMinutes().toString().padStart(2, '0');
                            break;
                    }
                    var fstyle = 'normal';
                    if (abs.type === 2)
                        fstyle = 'italic';

                    if ('id' in abs)
                        return (
                            <div key={abs.id} style={{ fontStyle: fstyle }}>
                                <Typography className={classes.title}><b>
                                    <FontAwesomeIcon icon={[iconCollReqStatus as any, (iconNameReqStatus as any)]} size="1x" fixedWidth title={iconHint as any} color={iconColorReqStatus as any} />&nbsp;
                                    {(abs.type === 2) && (
                                        t("Cancellation")
                                    )}
                                    {abs.absenceRequest.activityType.text}
                                </b></Typography>
                                <Grid container style={{ width: '100%' }} className={classes.tileCard}>
                                    <Grid item xs={12} sm={4} lg={4}>
                                        {t("StartDate")} : <b>{new Intl.DateTimeFormat('fr-CH').format(dateStart)}</b>
                                    </Grid>
                                    <Grid item xs={12} sm={4} lg={4}>
                                        {t("EndDate")} : <b>{new Intl.DateTimeFormat('fr-CH').format(dateEnd)}</b>
                                    </Grid>
                                    <Grid item xs={12} sm={3} lg={3}>
                                        {t("AbsenceRequestDate")} : <b>{new Intl.DateTimeFormat('fr-CH').format(dateRequest)}</b>
                                    </Grid>
                                    <Grid item xs={12} sm={1} lg={1}>
                                        <span style={{ width: '100%', display: 'inline-block', whiteSpace: 'nowrap', textAlign: 'right' }}>
                                            {(abs.isDeletable) &&
                                                (
                                                <FontAwesomeIcon icon="trash-alt" size="2x" title={t("Delete")} fixedWidth onClick={() => { handleClickOpen(abs.id) }} />
                                                )}
                                            {(!abs.isDeletable && abs.isCancellable) &&
                                                (
                                                <FontAwesomeIcon icon="undo-alt" size="2x" title={t("Cancel")} fixedWidth onClick={() => { handleClickOpen(abs.id) }} />
                                                )}
                                        </span>
                                    </Grid>
                                    {
                                        (period) &&
                                        <Grid item xs={12} sm={12} lg={12}>
                                            {period}
                                        </Grid>
                                    }
                                    {
                                        (abs.validatorRemark) &&
                                        <Grid item xs={12} sm={12} lg={12}>
                                            {t("Remark")} : {abs.validatorRemark}
                                        </Grid>
                                    }
                                </Grid>
                                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

                            </div>
                        );
                    else
                        return null;
                })
            }
            {(absences.length === 0 && isSearching) && (
                <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress /></div>
            )}
            {(absences.length === 0 && !isSearching) && (
                <div style={{ width: '100%', textAlign: 'center' }}>{t("NoData")}</div>
            )}
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                keepMounted
            >
                <DialogTitle id="alert-dialog-title">{t("Confirmation")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("AreYouSureToCancelThisRequest") }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={handleAccept} color="primary" autoFocus>
                        {t("Validate")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}