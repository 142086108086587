import * as React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DateFnsUtils from '@date-io/date-fns';
import { useAuth0 } from "@auth0/auth0-react";
import { httpHeader, handleFetchErrors, getApiUrl } from '../common/utils';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../common/styles';
import Divider from '@material-ui/core/Divider';

export default function MyEmployeeFile() {
    const classes = useStyles();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { t } = useTranslation();
    const [title, setTitle] = React.useState('0');
    const [titles, setTitles] = React.useState([{ id: '0', code: 'M.' }]);
    const [lastname, setLastname] = React.useState(' ');
    const [firstname, setFirstname] = React.useState(' ');
    const [compname, setCompname] = React.useState(' ');
    const [address, setAddress] = React.useState(' ');
    const [address2, setAddress2] = React.useState(' ');
    const [zipCode, setZipCode] = React.useState(' ');
    const [city, setCity] = React.useState(' ');
    const [country, setCountry] = React.useState('0');
    const [countries, setCountries] = React.useState([{ id: '0', text: 'Suisse' }]);
    const [birthDate, setBirthDate] = React.useState(null as Date | null);
    const [mobile, setMobile] = React.useState(' ');
    const [phone, setPhone] = React.useState(' ');
    const [email, setEmail] = React.useState(' ');

    const birthDateChanged = (date: Date | null) => { };
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {//when logged in
        if (isAuthenticated) {
            getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                const httpCmd = httpHeader('GET', token);

                fetch(getApiUrl((window as any).Config, 'hrportal') + '/currentemployee/address', httpCmd)
                    .then(res => handleFetchErrors(res).json())
                    .then(ret => {
                        if (ret.title && ret.title.trim().length > 0) {
                            setTitles([{ id: '0', code: ret.title }]);
                            setTitle('0');
                        }
                        if (ret.lastName && ret.lastName.trim().length > 0)
                            setLastname(ret.lastName);
                        if (ret.firstName && ret.firstName.trim().length > 0)
                            setFirstname(ret.firstName);
                        if (ret.nameSupplement && ret.nameSupplement.trim().length > 0)
                            setCompname(ret.nameSupplement);
                        if (ret.address1 && ret.address1.trim().length > 0)
                            setAddress(ret.address1);
                        if (ret.address2 && ret.address2.trim().length > 0)
                            setAddress2(ret.address2);
                        if (ret.zipCode && ret.zipCode.trim().length > 0)
                            setZipCode(ret.zipCode);
                        if (ret.city && ret.city.trim().length > 0)
                            setCity(ret.city);
                        if (ret.countryText && ret.countryText.trim().length > 0) {
                            setCountries([{ id: '0', text: ret.countryText }]);
                            setCountry('0');
                        }
                        if (ret.dateOfBirth && ret.dateOfBirth.trim().length > 0) {
                            //console.log(new Date(ret.dateOfBirth))
                            setBirthDate(new Date(ret.dateOfBirth));
                        }
                        if (ret.privateMobile && ret.privateMobile.trim().length > 0)
                            setMobile(ret.privateMobile);
                        if (ret.privatePhone && ret.privatePhone.trim().length > 0)
                            setPhone(ret.privatePhone);
                        if (ret.privateEmail && ret.privateEmail.trim().length > 0)
                            setEmail(ret.privateEmail);

                    }).catch(e => { console.log(e); });
            }).catch(e => { console.log(e); });
        };
    }, [isAuthenticated]);


    return (
        <Container maxWidth="sm" className={classes.centeredTopContainer}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography><FontAwesomeIcon icon="user" size="2x" fixedWidth className={classes.icons} /><span className={classes.pageTitleText}>{t("MyEmployeeFile")}</span></Typography>
                    <Divider className={classes.pageTitleDivider} />
                    <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="title-select-outlined-label" disabled >{t("Title")}</InputLabel>
                            <Select
                                labelId="title-select-outlined-label"
                                id="title-select-select-outlined"
                                value={title}
                                label={t("Title")}
                                displayEmpty
                                disabled
                            >
                                {
                                    titles.map((tle: any) => {
                                        if ('id' in tle && 'code' in tle)
                                            return (<MenuItem key={tle.id} value={tle.id}>{tle.code}</MenuItem>);
                                        else
                                            return null;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                disabled
                                id="name-outlined-disabled"
                                label={t("FirstName")}
                                variant="outlined"
                                value={firstname}
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                disabled
                                id="firstname-outlined-disabled"
                                label={t("LastName")}
                                variant="outlined"
                                value={lastname}
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                disabled
                                id="namecomp-outlined-disabled"
                                label={t("NameComplement")}
                                variant="outlined"
                                value={compname}
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                disabled
                                id="address-outlined-disabled"
                                label={t("Address")}
                                variant="outlined"
                                value={address}
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                disabled
                                id="address2-outlined-disabled"
                                label={t("Address2")}
                                variant="outlined"
                                value={address2}
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined" style={{ width: '100%', display: 'inline-block' }} className={classes.formControl}>
                            <div style={{ display: 'inline' }}>
                                <TextField
                                    disabled
                                    id="zipcode-outlined-disabled"
                                    label={t("ZipCode")}
                                    value={zipCode}
                                    variant="outlined"
                                    style={{ width: '30%' }}
                                />
                            </div>
                            <div style={{ display: 'inline', width: '70%' }}>
                                <TextField
                                    disabled
                                    id="city-outlined-disabled"
                                    label={t("City")}
                                    value={city}
                                    variant="outlined"
                                    style={{ width: '68%', float: 'right' }}
                                />
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="country-select-outlined-label" disabled >Pays</InputLabel>
                            <Select
                                labelId="country-select-outlined-label"
                                id="country-select-select-outlined"
                                value={country}
                                label={t("Country")}
                                displayEmpty
                                disabled
                            >
                                {
                                    countries.map((cty: any) => {
                                        if ('id' in cty && 'text' in cty)
                                            return (<MenuItem key={cty.id} value={cty.id}>{cty.text}</MenuItem>);
                                        else
                                            return null;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <FormControl className={classes.formControl}>
                                <KeyboardDatePicker
                                    disabled
                                    disableToolbar
                                    inputVariant="outlined"
                                    format="dd.MM.yyyy"
                                    margin="none"
                                    id="date-picker-inline"
                                    label={t("BirthDate")}
                                    value={birthDate}
                                    onChange={birthDateChanged}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                disabled
                                id="mobile-outlined-disabled"
                                label={t("MobilePhone")}
                                value={mobile}
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                disabled
                                id="phone-outlined-disabled"
                                label={t("Phone")}
                                value={phone}
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                disabled
                                id="mail-outlined-disabled"
                                label={t("Email")}
                                value={email}
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <Collapse in={open}>
                            <Alert severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                {t("ErrorLoadRetry")}
                            </Alert>
                        </Collapse>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    )
}