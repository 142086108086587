import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../common/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { httpHeader, handleFetchErrors, getApiUrl, convertToAuth0Language, checkModuleActive } from '../../common/utils';
import { SessionContext } from './../../App';

export default function CurrentAbsenceRequests(props: any) {
    //let ContractId = props.ContractId;
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const classes = useStyles();
    const { t } = useTranslation();

    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;

    const [isSearching, setIsSearching] = React.useState(false);
    const [absences, setAbsences] = React.useState([]);
    const [filteredAbsences, setFilteredAbsences] = React.useState(([] as any));
    const [years, setYears] = React.useState(([] as any));
    const [year, setYear] = React.useState('0');

    async function loadAll() {
        if (isSearching) return;

        try {
            setAbsences([] as any);
            setIsSearching(true);
            var httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('GET', token);
            }).catch(e => console.log(e));

            try {
                var res = await fetch(getApiUrl((window as any).Config, 'hrportal') + '/absence/requestshistory?addressid=' + state.AddressId + '&contractid=' + props.ContractId, httpCmd)

                var text = await handleFetchErrors(res).text();
                //console.log(text);

                if (text.length > 0) {
                    var json = JSON.parse(text);
                    //console.log(json);
                    if (json.requestHistory) {
                        await setAbsences(json.requestHistory);
                        //console.log(absences);
                        var tmpYears: any[] = [];
                        var tmpYear = 0;

                        json.requestHistory.forEach((a: any) => { //Refiltrage annuel
                            var yearFrom = new Date(a.absenceRequest.dateFrom).getFullYear();
                            var yearTo = new Date(a.absenceRequest.dateTo).getFullYear();

                            if (!tmpYears.includes(yearFrom))
                                tmpYears.push(yearFrom);
                            if (!tmpYears.includes(yearTo))
                                tmpYears.push(yearTo);
                        });

                        if (tmpYears.length > 0) {
                            if (tmpYears.includes((new Date().getFullYear())))
                                tmpYear = tmpYears[tmpYears.indexOf(new Date().getFullYear())];
                            else
                                tmpYear = tmpYears[0];
                        }
                        tmpYears = tmpYears.sort();
                        await setYears(tmpYears);
                        await setYear(tmpYear.toString());
                    }
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        finally {
            setIsSearching(false);
        };
    }

    const handleYear = React.useCallback(async (event) => {
        if (isAuthenticated && event.target.value > 0) {
            setYear(event.target.value);
        }
    }, [isAuthenticated]);

    React.useEffect(() => {//when logged in
        if (!isAuthenticated)
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
        else
            if (state.AddressId && state.AddressId > 0) {
                loadAll();
            }
    }, [isAuthenticated, state.AddressId, props.ContractId]);

    React.useEffect(() => { //Refiltrage annuel
        var intYear = parseInt(year);
        var fltAbs = absences.filter((a: any) => a && (new Date(a.absenceRequest.dateFrom).getFullYear()) <= intYear && (new Date(a.absenceRequest.dateTo).getFullYear()) >= intYear);
        setFilteredAbsences(fltAbs);
    }, [year])

    if (!checkModuleActive('Absences')) //Hidden module in config
        return (<></>);

    return (
        <React.Fragment>
            {(years.length > 0) &&
                (< div >
                <FormControl variant="outlined">
                    <InputLabel id="contract-select-outlined-label" >{t("Year")}</InputLabel>
                    <Select
                        labelId="contract-select-outlined-label"
                        id="contract-select-select-outlined"
                        value={year}
                        onChange={handleYear}
                        label={t("Year")}
                        displayEmpty
                    >
                        {
                            years.map((yr: any) => {
                                return (<MenuItem key={yr} value={yr}>{yr}</MenuItem>);
                            })
                        }
                    </Select>
                </FormControl>
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
            </div>)}
            <div>
                {
                    filteredAbsences.map((hist: any) => {
                        var dateStart = new Date(hist.absenceRequest.dateFrom)
                        var dateEnd = new Date(hist.absenceRequest.dateTo)
                        var dateRequest = new Date(hist.valueDate)

                        var iconNameReqStatus: string = ''
                        var iconColorReqStatus = ''
                        var iconHint = ''
                        var iconCollReqStatus = ''

                        switch (hist.requestStatus) {
                            case 2: //beingvalidated
                                iconNameReqStatus = 'hourglass-half';
                                iconColorReqStatus = '#FF6000';
                                iconHint = t("AbsenceRequestBeingValidated")
                                iconCollReqStatus = 'fas';
                                break;
                            case 3: //processed
                                if (hist.decision === 2) {
                                    iconNameReqStatus = 'check-circle';
                                    iconColorReqStatus = 'darkgreen';
                                    iconHint = t("AbsenceRequestValidated")
                                    iconCollReqStatus = 'far';
                                }
                                else {
                                    iconNameReqStatus = 'times-circle';
                                    iconColorReqStatus = 'red';
                                    iconHint = t("AbsenceRequestDenied")
                                    iconCollReqStatus = 'far';
                                }
                                break;
                            case 4:
                                iconNameReqStatus = 'ban';
                                iconColorReqStatus = 'red';
                                iconHint = t("AbsenceRequestCanceled")
                                iconCollReqStatus = 'fas';
                                break;
                            default: //undefined or transmitted
                                iconNameReqStatus = 'share-square';
                                iconColorReqStatus = 'blue';
                                iconHint = t("AbsenceRequestSended")
                                iconCollReqStatus = 'far';
                                break;
                        }

                        var period = ''
                        switch (hist.absenceRequest.period) {
                            case 2:
                                period = t("Morning");
                                break;
                            case 3:
                                period = t("Afternoon");
                                break;
                            case 4:
                                var timeStart = new Date(hist.absenceRequest.timeFrom)
                                var timeEnd = new Date(hist.absenceRequest.timeTo)

                                period = t("TimeSlot") + ' ' + t("From") + ' ' + timeStart.getHours() + ':' + timeStart.getMinutes().toString().padStart(2, '0') + ' ' + t("Until") + ' ' + timeEnd.getHours() + ':' + timeEnd.getMinutes().toString().padStart(2, '0');
                                break;
                        }

                        var fstyle = 'normal';
                        if (hist.type === 2)
                            fstyle = 'italic';

                        if ('id' in hist)
                            return (
                                <div key={hist.id} style={{ fontStyle: fstyle }}>
                                    <Typography className={classes.title}><b>
                                        <FontAwesomeIcon icon={[iconCollReqStatus as any, (iconNameReqStatus as any)]} size="1x" fixedWidth title={iconHint as any} color={iconColorReqStatus as any} />&nbsp;
                                        {(hist.type === 2) && (
                                            t("Cancellation")
                                        )}
                                        {hist.absenceRequest.activityType.text}
                                    </b></Typography>
                                    <Grid container style={{ width: '100%' }} className={classes.tileCard}>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            {t("StartDate")} : <b>{new Intl.DateTimeFormat('fr-CH').format(dateStart)}</b>
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            {t("EndDate")} : <b>{new Intl.DateTimeFormat('fr-CH').format(dateEnd)}</b>
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            {t("AbsenceRequestDate")} : <b>{new Intl.DateTimeFormat('fr-CH').format(dateRequest)}</b>
                                        </Grid>
                                        {
                                            (period) &&
                                            <Grid item xs={12} sm={12} lg={12}>
                                                {period}
                                            </Grid>
                                        }
                                        {
                                            (hist.validatorRemark) &&
                                            <Grid item xs={12} sm={12} lg={12}>
                                                {hist.validatorRemark}
                                            </Grid>
                                        }
                                    </Grid>
                                    <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                                </div>
                            );
                        else
                            return null;
                    })
                }
                {(absences.length === 0 && isSearching) && (
                    <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress /></div>
                )}
                {(absences.length === 0 && !isSearching) && (
                    <div style={{ width: '100%', textAlign: 'center' }}>{t("NoData")}</div>
                )}
            </div>
        </React.Fragment>
    );
}