import * as React from 'react';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { CardContent, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { emptySession } from '../../common/utils';
import { useStyles } from '../../common/styles';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from "@auth0/auth0-react";
import { httpHeader, handleFetchErrors, getApiUrl, getBaseUrl, convertToAuth0Language, checkModuleActive } from '../../common/utils';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import {
    Link,
    useRouteMatch,
    useHistory,
    useLocation
} from "react-router-dom";
import NewAbsenceRequest from './NewAbsenceRequest';
import CurrentAbsenceRequests from './CurrentAbsenceRequests';
import AbsenceRequestHistory from './AbsenceRequestHistory';
import { SessionContext } from '../../App';
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div style={{ margin: '20px' }}>{children}</div>
            )}
        </div>
    );
}

export default function AbsenceRequestEmployee() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const match = useRouteMatch();
    const history = useHistory();
    const theme = useTheme();

    const noContract = { id: 0, contractNumber: t('None'), hrPortalDefault: false };
    const [contract, setContract] = React.useState('0');
    const [contracts, setContracts] = React.useState([noContract]);
    const [value, setValue] = React.useState('0');
    const [showChildren, setShowChildren] = React.useState(false);

    let session: SessionState = emptySession(); // { AddressId: 0, ContractId: 0, FirstName: '', LastName: '', EmployeeNo: '', PendingAbsenceRequests: 0, PendingAbsenceValidation: 0, IsAbsenceEnabled: false, IsValidateManager: false }; //emptySession();

    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;

    const allTabs = [`${match.path}/newabsencerequest`, `${match.path}/currentabsencerequests`, `${match.path}/absencerequesthistory`];

    const handleChange = React.useCallback(async (event: React.ChangeEvent<{}>, newValue: string) => {
        if (isAuthenticated) {
            //setValue(newValue);
            history.push(allTabs[parseInt(newValue)]);
        }
    }, [isAuthenticated]);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const tabNewRequestProps = {
        label: isSmallScreen ? t("Request") : t("NewRequest")
    };

    const tabAbsenceCurrentProps = {
        label: isSmallScreen ? t("Current") : t("AbsenceCurrent")
    };

    const tabHistoryProps = {
        label: isSmallScreen ? t("History").substr(0, 5) : t("History")
    };


    const updateContracts = async (ret: any) => {
        //console.log(ret);
        var selected = ret.contracts.filter((c: any) => c.hrPortalDefault);

        await setContracts(a => [...a.filter(a => a.id !== 0), noContract]);
        if (selected.length === 0)
            ret.contracts.unshift(noContract);

        //console.log(ret);
        if (Array.isArray(selected) && selected.length > 0) {
            await setContracts(ret.contracts);
            await setContract(selected[0].id);
            session.ContractId = parseInt(selected[0].id);
            if (ret.contracts[0].id === 0)
                ret.contracts.shift();
        }
        else {
            await setContracts(ret.contracts);
        }
    }

    const handleChangeContract = React.useCallback(async (event) => {
        //console.log(event.target.value);
        if (isAuthenticated && event.target.value > 0) {
            await setContract(event.target.value);

            session.ContractId = parseInt(event.target.value);
            if (session.ContractId > 0) {
                for (var k in state)
                    if (k !== 'ContractId')
                        (session as any)[k] = (state as any)[k];

                //console.log(session);
                //await dispatch({ type: 'update', data: session } as SessionAction);
            }
        }
    }, [isAuthenticated]);

    async function fetchContract() {
        let httpCmd = {};
        await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {

            httpCmd = httpHeader('GET', token);
        }).catch(e => console.log(e));

        var returned;
        await fetch(getApiUrl((window as any).Config, 'hrportal') + '/currentemployee/contracts', httpCmd)
            .then(res => handleFetchErrors(res).json())
            .then(async (ret) =>
                await updateContracts(ret)
            )
            .catch(e => console.log(e));
    }

    function a11yProps(index: any) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    React.useEffect(() => {//when logged in
        if (isAuthenticated) {
            const fetchInfos = async () => {
                if (parseInt(contract) < 1)
                    await fetchContract();
            };
            fetchInfos();
        }
        else {
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
        }

    }, [isAuthenticated, state, contract]);

    const location = useLocation();

    React.useEffect(() => {
        let tab = allTabs.indexOf(window.location.pathname);

        if (tab >= 0)
            setValue(tab.toString());
    }, [location]);

    React.useEffect(() => { //ensure we have a value to render the children, this avoid passing 0 as contractId to them.
        if (parseInt(contract) > 0)
            setShowChildren(true);
    }, [contract])

    if (!checkModuleActive('Absences')) //Hidden module in config
        return (<></>);

    return (<Container maxWidth="md" className={classes.centeredTopContainer}>
        <Card className={classes.card}>
            <CardContent>
                <Typography><FontAwesomeIcon icon="umbrella-beach" size="2x" fixedWidth className={classes.icons} /><span className={classes.pageTitleText}>{t("TimeOff")}</span></Typography>
                <Divider className={classes.pageTitleDivider} />
                <Grid container>
                    <Grid item xs={12} sm={12} lg={6} md={6} xl={6} style={{ marginBottom: '20px' }}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="contract-select-outlined-label" >{t("DefaultContract")}</InputLabel>
                            <Select
                                labelId="contract-select-outlined-label"
                                id="contract-select-select-outlined"
                                value={contract}
                                onChange={handleChangeContract}
                                label={t("DefaultContract")}
                                displayEmpty
                            >
                                {
                                    contracts.map((ctr: any) => {
                                        if ('id' in ctr && 'contractNumber' in ctr)
                                            return (<MenuItem key={ctr.id} value={ctr.id}>{ctr.contractNumber}</MenuItem>);
                                        else
                                            return null;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <TabContext value={value}>
                    <Paper className={classes.flexGrow}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab {...tabNewRequestProps} value="0" icon={<FontAwesomeIcon icon="calendar-plus" />} component={Link} to={allTabs[0]} {...a11yProps(0)} />
                            <Tab {...tabAbsenceCurrentProps} value="1" icon={<FontAwesomeIcon icon="calendar-check" />} component={Link} to={allTabs[1]} {...a11yProps(1)} />
                            <Tab {...tabHistoryProps} value="2" icon={<FontAwesomeIcon icon="history" />} component={Link} to={allTabs[2]} {...a11yProps(2)} />
                            {state.IsValidateManager &&
                                (<Tab icon={<FontAwesomeIcon icon="chevron-right" />} onClick={() => { history.push(getBaseUrl() + '/absencesvalidate/validatableabsencerequests'); }} />)
                            }
                        </Tabs>
                    </Paper>
                    {showChildren && (
                        <React.Fragment>
                            <TabPanel value={value} index="0">
                                <NewAbsenceRequest ContractId={contract} />
                            </TabPanel>
                            <TabPanel value={value} index="1">
                                <CurrentAbsenceRequests ContractId={contract} />
                            </TabPanel>
                            <TabPanel value={value} index="2">
                                <AbsenceRequestHistory ContractId={contract} />
                            </TabPanel>
                        </React.Fragment>)
                    }
                </TabContext>
            </CardContent>
        </Card>
    </Container>
    );
}