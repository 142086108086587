import * as React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


export default function LoginTest() {

    const footerHeight = 64;
    const toolbarHeight = 64;

    const { user } = useAuth0();

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
                minHeight: '100%',
            },
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
            menuButton: {
            },
            hide: {
                display: 'none',
            },
            drawerClose: {
                overflowX: 'hidden',
                width: 0,
                margin: 0,
                padding: 0,
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9) + 1,
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                },
            },
            toolbar: {
                display: 'flex',
                //flex: 0 1 auto;
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: theme.spacing(0, 1),
                // necessary for content to be below app bar
                ...theme.mixins.toolbar,
            },
            content: {
                flexGrow: 1,
                [theme.breakpoints.down('sm')]: {
                    padding: 0
                },

            },
            userName: {
                fontSize: '1em',
                marginRight: '0.7em',
                display: 'inline',
                height: '100%',
                textAlign: 'center'
            },
            userIcon: {
                height: '100%',
                textAlign: 'center'
            },
            userAvatar: {
                backgroundColor: '#00505B',
                display: 'inline-flex',
                width: theme.spacing(4),
                height: theme.spacing(4),
            },
            logo: {
                height: '42px',
                width: '42px',
                backgroundColor: '#FFF',
                marginRight: '0.7em',
            },
            topContainerHide: {
                [theme.breakpoints.down('xs')]: {
                    display: 'none',
                }
            },
            primaryText: {
                color: theme.palette.text.primary
            },
            footer: {
                display: 'flex',
                height: footerHeight + 'px',
                backgroundColor: theme.palette.text.primary,
                bottom: 0,
                width: '100%',
                color: theme.palette.grey[100],
                justifyContent: 'center',
                alignItems: 'center'
            },
            subcontent: {
                height: `calc(100% - ${toolbarHeight + footerHeight}px)`,
                padding: theme.spacing(0),
                [theme.breakpoints.up('sm')]: {
                    padding: theme.spacing(3),
                }
            },
            background: {
                height: '100%',
                backgroundImage: `url("hrhome.jpg")`,
                opacity: '0.85',
            }
        }),
    );


    console.log(user);
    const classes = useStyles();
    return (<div>
           (!isAuthenticated) && (
            <div className={classes.root}>
                <div className={classes.content}>
                    <div style={{ marginTop: `${toolbarHeight}px`, height: `calc(100% - ${toolbarHeight + footerHeight}px)`, textAlign: 'center' }}>
                        <div className={classes.background}></div>
                    </div>
                    <div className={classes.footer}>
                        ©2021 BMCSSI SA - Powered by Strategic Web.
                    </div>
                </div>
            </div>
        )</div>
    )
}