import * as React from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { CardContent, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../common/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { httpHeader, handleFetchErrors, getApiUrl, getIconByFileName, convertToAuth0Language, checkModuleActive } from '../common/utils';
import { SessionContext } from './../App';
import { downloadDocument } from '../common/utils';
import * as rdd from 'react-device-detect';

export default function HRDocument() {
    const QTY_BY_PAGE = 20;
    const { t } = useTranslation();
    const classes = useStyles();
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [documents, setDocuments] = React.useState([]);
    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;
    const [query, setQuery] = React.useState({ url: '', pageNo: 0, allLoad: false });
    const [isSearching, setIsSearching] = React.useState(false);

    window.onscroll = () => {
        if (document.documentElement.scrollHeight - (document.documentElement.offsetHeight / 2) <= document.documentElement.scrollTop + document.documentElement.offsetHeight) {
            loadNextPage({ ...query, pageNo: query.pageNo + 1 });
        }
    }

    const loadNextPage = async (newQuery: any) => {
        if (isSearching) return;
        if (newQuery.allLoad) return;

        setQuery(newQuery);
        try {

            setIsSearching(true);
            var httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('GET', token);
            }).catch(e => console.log(e));

            try {
                var res = await fetch(getApiUrl((window as any).Config, 'hrportal') + '/document?type=general&addressid=' + state.AddressId + '&contractid=' + state.ContractId + '&pageno=' + newQuery.pageNo + '&pagesize=' + QTY_BY_PAGE, httpCmd)

                var text = await handleFetchErrors(res).text();
                //console.log(text);

                if (text.length > 0) {
                    var json = JSON.parse(text);

                    //console.log('before set');
                    if (newQuery.pageNo === 0)
                        setDocuments(json.documents);
                    else
                        setDocuments(documents.concat(json.documents));
                }
                else
                    await setQuery(query => ({ ...query, allLoad: true })); //tout est loadé !
            }
            catch (e) {
                console.log(e);
            }
        }
        finally {
            setIsSearching(false);
        };
    }

    React.useEffect(() => {//when logged in
        if (isAuthenticated && state.AddressId && state.AddressId > 0) {
            loadNextPage(query);
        }
        else
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
    }, [isAuthenticated, state]);

    const downloadHRDoc = async function (fileGuid: string) {
        await downloadDocument(
            fileGuid,
            '/document/' + fileGuid + '?type=general&addressid=' + state.AddressId + '&contractid=' + state.ContractId,
            rdd.isSafari ? window.open() : null, //Pour bizarrerie Safari 25.08.21 / JMA Ticket 154222
            getAccessTokenSilently
        ).catch(e => { alert(t('ErrorLoad')) });
    }

    if (!checkModuleActive('HRDocuments')) //Hidden module in config
        return (<></>);

    return (
        <Container maxWidth="md" className={classes.centeredTopContainer}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography><FontAwesomeIcon icon="file-alt" size="2x" fixedWidth className={classes.icons} /><span className={classes.pageTitleText}>{t("HRDocuments")}</span></Typography>
                    <Divider className={classes.pageTitleDivider} />
                    {
                        documents.map((doc: any) => {
                            if ('title' in doc)
                                return (<Grid container style={{ width: '100%' }} key={doc.guid} className={classes.tileCard} onClick={() => { downloadHRDoc(doc.guid) }}>
                                    <Grid item xs={2} sm={2} lg={1}>
                                        <FontAwesomeIcon icon={getIconByFileName(doc.fileName)} size="2x" fixedWidth title={doc.fileName} />
                                    </Grid>
                                    <Grid item xs={10} sm={10} lg={11}><Typography variant="h6">{doc.title}</Typography>
                                        <Typography className={classes.title}>{doc.description}</Typography>
                                    </Grid>
                                </Grid>);
                            else
                                return null;
                        })
                    }
                    {(document.documentElement.scrollHeight - 50 <= document.documentElement.clientHeight) && !query.allLoad && documents.length % QTY_BY_PAGE === 0 && (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}><Button variant="contained" color="default" size="medium" onClick={() => { loadNextPage({ ...query, pageNo: query.pageNo + 1 }); }}>
                            {t("Loading")}
                        </Button></div>
                    )}
                </CardContent>
            </Card>
        </Container>
    );
}