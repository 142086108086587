import * as React from 'react';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { CardContent, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../common/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { httpHeader, handleFetchErrors, getApiUrl } from '../common/utils';
import { SessionContext } from './../App';

export default function HRDocumentTile() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [documents, setDocuments] = React.useState([]);
    const history = useHistory();
    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;

    React.useEffect(() => {//when logged in
        if (isAuthenticated && state.AddressId && state.AddressId > 0) {
            getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                var httpCmd = httpHeader('GET', token);
                fetch(getApiUrl((window as any).Config, 'hrportal') + '/news?addressid=' + state.AddressId + '&contractid=' + state.ContractId + '&pageno=0&pagesize=3', httpCmd)
                    .then(res => handleFetchErrors(res).json())
                    .then(ret => {
                        //console.log(ret);
                        setDocuments(ret.documents);
                    }).catch(e => console.log(e));
            }).catch(e => console.log(e));
        }
    }, [isAuthenticated, state]);

    return (
        <Card className={classes.tileCard} onClick={() => { history.push(process.env.PUBLIC_URL + "/news"); }}>
            <CardContent>
                <Typography className={classes.tileTitle}><FontAwesomeIcon icon="newspaper" size="2x" fixedWidth className={classes.icons} /><span className={classes.tileTitleText}>{t("News")}</span></Typography>
                <Divider />
                <ul>
                    {
                        documents.map((news: any) => {
                            if ('title' in news)
                                return (<li key={news.guid} className={classes.tileElement}>{news.title}</li>);
                            else
                                return null;
                        })
                    }
                </ul>
            </CardContent>
        </Card>
    );
}