/// <reference path = "common/globaldef.d.ts" />
import React from 'react';
import { Route } from 'react-router';
import NavMenu from './components/NavMenu';
import Home from './components/Home';
import LoginTest from './components/LoginTest';
import MyAccount from './components/MyAccount';
import MyEmployeeFile from './components/MyEmployeeFile';
import HRDocument from './components/HRDocument';
import HRNews from './components/HRNews';
import PersoDocument from './components/PersoDocument';
import SalaryDocument from './components/SalaryDocument';
import TimePeriodBalance from './components/TimePeriodBalance';
import AbsenceRequestEmployee from './components/absences/AbsenceRequestEmployee';
import AbsenceRequestManager from './components/absences/AbsenceRequestManager';
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faBars, faCoffee, faSignInAlt, faSignOutAlt, faHome, faUser } from '@fortawesome/free-solid-svg-icons'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { emptySession, getAppName } from './common/utils';


library.add(fas, far, faCoffee, faSignInAlt, faSignOutAlt, faBars, faHome, faUser)

export const SessionContext = React.createContext({} as ISessionContext);

const initialState = emptySession(); // { AddressId: 0, ContractId: 0, FirstName: '', LastName: '', EmployeeNo: '', PendingAbsenceRequests: 0, PendingAbsenceValidation: 0, IsAbsenceEnabled: false, IsValidateManager: false };

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
            height: '100%'
        }
    }));

function sessionReducer(state: SessionState, action: SessionAction) {
    switch (action.type) {
        case 'update': {
            (window as any).Session = state;
            return (window as any).Session;
        }
        case 'state': {
            return action.data //(window as any).Session;
        }
        case 'reset': {
            (window as any).Session = initialState;
            return initialState;
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}


function App() {
    const classes = useStyles();
    const [state, dispatch] = React.useReducer(sessionReducer, initialState);

    document.title = getAppName();

    //Check connection on the API, greatly reduce first loading time.
    var httpCmd = {
        method: "GET"
    };

    fetch((window as any).Config.ApiBaseUrl + 'v1/CheckConnection', httpCmd);

    return (<div className={classes.root}>
        <SessionContext.Provider value={{ state, dispatch } as ISessionContext}>
            <NavMenu>
                <Route exact path={process.env.PUBLIC_URL + '/'} component={Home} />
                <Route exact path={process.env.PUBLIC_URL + '/login-test'} component={LoginTest} />
                <Route exact path={process.env.PUBLIC_URL + '/myaccount'} component={MyAccount} />
                <Route exact path={process.env.PUBLIC_URL + '/myemployeefile'} component={MyEmployeeFile} />
                <Route exact path={process.env.PUBLIC_URL + '/hrdocument'} component={HRDocument} />
                <Route exact path={process.env.PUBLIC_URL + '/personaldocument'} component={PersoDocument} />
                <Route exact path={process.env.PUBLIC_URL + '/salarydocument'} component={SalaryDocument} />
                <Route exact path={process.env.PUBLIC_URL + '/timebalance'} component={TimePeriodBalance} />
                <Route exact path={process.env.PUBLIC_URL + '/news'} component={HRNews} />
                <Route path={process.env.PUBLIC_URL + '/absences'} component={AbsenceRequestEmployee} />
                <Route path={process.env.PUBLIC_URL + '/absencesvalidate'} component={AbsenceRequestManager} />
            </NavMenu>
        </SessionContext.Provider>
    </div>)
}

export default App;

