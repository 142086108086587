import React from 'react';

import Scheduler, { Resource } from 'devextreme-react/scheduler';
import 'devextreme/dist/css/dx.light.css';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import frMessages from "devextreme/localization/messages/fr.json";
import { locale, loadMessages } from "devextreme/localization";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import { SessionContext } from './../../App';
import { httpHeader, handleFetchErrors, getApiUrl, convertToAuth0Language, checkModuleActive } from '../../common/utils';
import { useStyles } from '../../common/styles';
import { useTheme } from '@material-ui/core/styles';
import './AbsenceRequestPlanning.css' //Must be defined after !
import { time } from 'console';

const currentDate = new Date();
const views = ['timelineMonth'] as any;
const groups = ['ownerId'];

export default function AbsenceRequestsPlanning() {
    loadMessages(frMessages);
    locale(convertToAuth0Language(''));

    const { t } = useTranslation();

    const classes = useStyles();
    const theme = useTheme();

    const requestStatus = [{
        text: t('AbsenceRequestSended'),
        id: 1,
        color: theme.palette.primary.main
    }, {
        text: t('AbsenceRequestBeingValidated'),
        id: 2,
        color: '#EA5D00'
    }, {
        text: t('AbsenceRequestValidated'),
        id: 3,
        color: '#30B726'
    }, {
        text: 'ShouldNotBeDisplayed',
        id: 4,
        color: 'red'
    }];

    const [isSearching, setIsSearching] = React.useState(false);
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;

    const [resources, setResources] = React.useState([] as any);
    const [datas, setDatas] = React.useState([] as any);

    const noDepartment = { id: 0, code: t('Alls'), text: t('Alls') };
    const [Departments, setDepartments] = React.useState([noDepartment]);

    const noWorkCenter = { id: 0, code: t('Alls'), text: t('Alls') };
    const [WorkCenters, setWorkCenters] = React.useState([noWorkCenter]);

    const formDataInitialState = {
        DepartmentId: '0', WorkCenterId: '0',
    }

    const [formData, setFormData] = React.useState(formDataInitialState);

    const handleDepartments = React.useCallback(async (event) => {
        if (isAuthenticated && event.target.value > -1) {
            setFormData(inputs => ({ ...inputs, 'DepartmentId': event.target.value }));
        }
    }, [isAuthenticated]);

    const handleWorkCenters = React.useCallback(async (event) => {
        if (isAuthenticated && event.target.value > -1) {
            setFormData(inputs => ({ ...inputs, 'WorkCenterId': event.target.value }));
        }
    }, [isAuthenticated]);

    const lastDayOfTheMonth = (year: number, month: number) => {
        return new Date(year, month + 1, 0);
    }
    const firstDayOfTheMonth = (year: number, month: number) => {
        return new Date(year, month, 1);
    }

    function groupByTranspose(collection: any, properties: string[] /*to group by properties*/) {
        var val: any[] = [], index = 0, prop: string,
            values: any[] = [], results: any[] = [];

        for (var j = 0; j < properties.length; j++) {
            values[j] = [];
        }

        for (var i = 0; i < collection.length; i++) {
            index = 0;
            for (var j = 0; j < properties.length; j++) {
                val[j] = collection[i][properties[j]];
                if (values[j].indexOf(val[j]) < index) //if at least one of the element is not in the collection, index = -1
                    index = values[j].indexOf(val[j]);
                //console.log(val[j] + '-->' + values[j].indexOf(val[j]) + ' < ' + index);
            }
            if (index == -1)
                for (var j = 0; j < properties.length; j++) {
                    values[j].push(val[j]);
                    prop = properties[j];
                }
        }
        if (values.length >= 1) // copy the arrays into objects.
            for (var i = 0; i < values[0].length; i++) {
                results[i] = {}
                for (var j = 0; j < properties.length; j++) {
                    results[i][properties[j]] = values[j][i];
                }
            }

        return results;
    }

    async function loadDepartments() {
        var httpCmd = {};
        await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
            httpCmd = httpHeader('GET', token);
        }).catch(e => loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL }));

        await fetch(getApiUrl((window as any).Config, 'hrportalparameters') + '/lookup/departments', httpCmd)
            .then(res => handleFetchErrors(res).json())
            .then(ret => {
                setDepartments([...ret.lstDepartments, noDepartment]);
                setFormData(inputs => ({ ...inputs, 'WorkCenterId': '0' }));
            })
            .catch(e => console.log(e));
    }

    async function loadPlanning(startDate:Date, endDate:Date) {
        if (isSearching) return;

        try {
            setIsSearching(true);
            setResources([]);

            setDatas([]);
            var httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('GET', token);
            }).catch(e => loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL }));

            try {
                var queryend = '';
                
                queryend += '&dateform=' + new Date(startDate.getTime() + (startDate.getTimezoneOffset() * 60000 * -1)).toISOString();
                queryend += '&dateuntil=' + new Date(endDate.getTime() + (endDate.getTimezoneOffset() * 60000 * -1)).toISOString();

                queryend += parseInt(formData.DepartmentId) > 0 ? '&departmentid=' + formData.DepartmentId : '';
                queryend += parseInt(formData.WorkCenterId) > 0 ? '&workcenterid=' + formData.WorkCenterId : '';

                var res = await fetch(getApiUrl((window as any).Config, 'hrportal') + '/absencevalidation/planning?addressid=' + state.AddressId + '&contractid=' + state.ContractId + queryend, httpCmd)

                var text = await handleFetchErrors(res).text();
                //console.log(text);

                if (text.length > 0) {
                    var json = JSON.parse(text);
                    //console.log(json);
                    //console.log(absences);
                    var resourc = await groupByTranspose(json.planningElements, ["employeeAdName", "employeeContractId", "employementContractNumber"])
                    //console.log(resourc);

                    for (var i = 0; i < resourc.length; i++) {
                        resourc[i] = { ...resourc[i], id: parseInt(resourc[i].employeeContractId) }
                    }
                    //console.log(resources);

                    var flattenDatas = [];
                    for (var i = 0; i < json.planningElements.length; i++) {
                        //console.log(json.planningElements[i].absenceRequest);
                        var dateFrom = new Date(json.planningElements[i].absenceRequest.dateFrom);
                        var dateTo = new Date(json.planningElements[i].absenceRequest.dateTo);
                        var timeFrm = new Date(json.planningElements[i].absenceRequest.timeFrom);
                        var timeT = new Date(json.planningElements[i].absenceRequest.timeTo);


                        //console.log(json.planningElements[i].absenceRequest.period)
                        if (json.planningElements[i].absenceRequest.period === 1) {
                            dateFrom.setHours(0, 0, 0, 0);
                            dateTo.setHours(23, 59, 59, 999);
                        }
                        if (json.planningElements[i].absenceRequest.period === 2) {
                            dateFrom.setHours(0, 0, 0, 0);
                            dateTo.setHours(12, 0, 0, 0);
                        }
                        if (json.planningElements[i].absenceRequest.period === 3) {
                            dateFrom.setHours(12, 0, 0, 0);
                            dateTo.setHours(23, 59, 59, 999);
                        }
                        if (json.planningElements[i].absenceRequest.period === 4) {
                            if (timeFrm) {
                                dateFrom.setHours(timeFrm.getHours(), timeFrm.getMinutes(), timeFrm.getSeconds());
                            }
                            if (timeT) {
                                dateTo.setHours(timeT.getHours(), timeT.getMinutes(), timeT.getSeconds());
                            }
                        }

                        /*console.log(dateFrom);
                        console.log(dateTo);*/

                        var textEle = json.planningElements[i].absenceRequest.activityType.text
                        if (dateFrom)
                            textEle = textEle + ' ' + dateFrom.getDate() + '.' + (dateFrom.getMonth() + 1);
                        if (dateTo)
                            textEle = textEle + ' - ' + dateTo.getDate() + '.' + (dateTo.getMonth() + 1);

                        switch (json.planningElements[i].absenceRequest.period) {
                            case 1:
                                textEle = textEle + ' ' + t('AllDay').toLowerCase();
                                break;
                            case 2:
                                textEle = textEle + ' ' + t('Morning').toLowerCase();
                                break;
                            case 3:
                                textEle = textEle + ' ' + t('Afternoon').toLowerCase();
                                break;
                            case 4:
                                textEle = textEle + ' ' + t('TimeSlot').toLowerCase();

                                if (timeFrm) {
                                    textEle = textEle + ' ' + timeFrm.getHours() + ':' + timeFrm.getMinutes();
                                }
                                if (timeT) {
                                    textEle = textEle + '-' + timeT.getHours() + ':' + timeT.getMinutes();
                                }
                                break;
                        }

                        flattenDatas.push({
                            startDate: dateFrom,
                            endDate: dateTo,
                            employeeContractId: [json.planningElements[i].employeeContractId],
                            text: textEle,
                            ownerId: [parseInt(json.planningElements[i].employeeContractId)],
                            allDay: json.planningElements[i].absenceRequest.period == 1,
                            statusId: [json.planningElements[i].requestStatus]
                        })
                    };
                    setDatas(flattenDatas)
                    setResources(resourc);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        finally {
            setIsSearching(false);
        };
    }

    async function loadWorkCenters() {
        var httpCmd = {};

        await setWorkCenters([noWorkCenter]);

        await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
            httpCmd = httpHeader('GET', token);
        }).catch(e => loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL }));

        await fetch(getApiUrl((window as any).Config, 'hrportalparameters') + '/lookup/workcenters?departmentid=' + formData.DepartmentId, httpCmd)
            .then(res => handleFetchErrors(res).json())
            .then(ret => {
                setWorkCenters([...ret.lstWorkCenters, noWorkCenter]);
            })
            .catch(e => console.log(e));
    }

    React.useEffect(() => {//when logged in
        if (!isAuthenticated)
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
        else
            if (state.AddressId && state.AddressId > 0) {
                var curDate = new Date() ;
                loadPlanning(firstDayOfTheMonth(curDate.getFullYear(), curDate.getMonth()), lastDayOfTheMonth(curDate.getFullYear(), curDate.getMonth()));
            }
    }, [isAuthenticated, state, formData.DepartmentId, , formData.WorkCenterId]);

    React.useEffect(() => {//when logged in
        if (!isAuthenticated)
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
        else
            if (state.AddressId && state.AddressId > 0) {
                loadWorkCenters();
                setFormData(inputs => ({ ...inputs, 'WorkCenterId': '0'}));
            }
    }, [isAuthenticated, state, formData.DepartmentId]);

    React.useEffect(() => {
        if (!isAuthenticated)
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
        else
            loadDepartments();
    }, [isAuthenticated])
    /*React.useEffect(() => {
        console.log(datas);
    }, [datas]);
    React.useEffect(() => {
        console.log(resources);
    }, [resources]);*/

    const onOptionChanged = (e: any) => {
        if(e !== undefined && e !== null)
            if (e.name === 'currentDate') {
                var curDate = (e.value as Date)
                loadPlanning(firstDayOfTheMonth(curDate.getFullYear(), curDate.getMonth()), lastDayOfTheMonth(curDate.getFullYear(), curDate.getMonth()));
            }
    }

    if (!checkModuleActive('Absences')) //Hidden module in config
        return (<></>);

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="contract-select-outlined-label" >{t("Department")}</InputLabel>
                        <Select
                            labelId="contract-select-outlined-label"
                            id="contract-select-select-outlined"
                            value={formData.DepartmentId}
                            onChange={handleDepartments}
                            label={t("Department")}
                            displayEmpty
                        >
                            {
                                Departments.map((department: any) => {
                                    if ('id' in department)
                                        return (<MenuItem key={department.id} value={department.id}>{department.text}</MenuItem>);
                                    else
                                        return null;
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="contract-select-outlined-label" >{t("WorkCenter")}</InputLabel>
                        <Select
                            labelId="contract-select-outlined-label"
                            id="contract-select-select-outlined"
                            value={formData.WorkCenterId}
                            onChange={handleWorkCenters}
                            label={t("WorkCenter")}
                            displayEmpty
                        >
                            {
                                WorkCenters.map((workcenter: any) => {
                                    if ('id' in workcenter)
                                        return (<MenuItem key={workcenter.id} value={workcenter.id}>{workcenter.text}</MenuItem>);
                                    else
                                        return null;
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Scheduler
                dataSource={datas}
                views={views}
                defaultCurrentView="timelineMonth"
                defaultCurrentDate={currentDate}
                groups={groups}
                height={'80%'}
                firstDayOfWeek={1}
                startDayHour={0}
                endDayHour={24}
                crossScrollingEnabled={true}
                editing={false}
                descriptionExpr="text"
                dateCellRender={(data, index) => (<div>{data.date.getDay() > 0 && data.date.getDay() < 6 ? data.date.getDate() : <b>{data.date.getDate()}</b>}</div>)}
                appointmentRender={(data, index) => {
                    //console.log(data)
                    return (<div>{data.appointmentData.text}</div>);
                }}
                onOptionChanged={onOptionChanged}
            >
                <Resource
                    fieldExpr="ownerId"
                    displayExpr={(e: any) => (e.employeeAdName + ' (' + e.employementContractNumber + ')')}
                    allowMultiple={false}
                    dataSource={resources}
                    label={t("Employee")}
                />
                <Resource
                    fieldExpr="statusId"
                    allowMultiple={false}
                    dataSource={requestStatus}
                    label={t("Status")}
                    useColorAsDefault={true}
                />
            </Scheduler>
        </React.Fragment>
    );
}


