import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)


export const resources = {
    en: { //Not verified/validated translation !!
        translation: {
            "Welcome to React": "Welcome to React and react-i18next",
            "News": "News",
            "Home": "Home",
            "HoursBalance": "Hours balance",
            "Hours": "Hours",
            "HRDocuments": "HR documents",
            "Documents": "Documents",
            "SalaryDocument": "Salary documents",
            "PersonalDocuments": "Personal documents",
            "TimeOff": "Time off",
            "MyAccount": "My account",
            "MyProfile": "My profile",
            "DefaultContract": "Default contract",
            "ChangePassword": "Change password",
            "MyEmployeeFile": "My employee file",
            "Preferences": "Preferences",
            "ErrorRetry": "An error occured, please retry.",
            "Logout": "Logout",
            "MainFooterText1": "©2021 BMC Société de Services Informatiques SA",
            "MainFooterText2": " - Powered by Strategic Web.",
            "Title": "Title",
            "FirstName": "First name",
            "LastName": "Last name",
            "NameComplement": "Name complement",
            "Address": "Address",
            "Address2": "Address 2",
            "ZipCode": "Zip code",
            "City": "City",
            "Country": "Country",
            "BirthDate": "Birth date",
            "MobilePhone": "Mobile phone",
            "Phone": "Phone",
            "Email": "Email",
            "Loading": "Loading...",
            "Refresh": "Refresh",
            "Type": "Type",
            "Salary": "Salaire",
            "Certificate": "Certificat",
            "Year": "Year",
            "Period": "Period",
            "All": "All",
            "Alls": "All",
            "NoData": "No data available.",
            "Code": "Code",
            "ActivityType": "Leave type",
            "Balance": "Balance",
            "Contract": "Contract",
            "None": "None",
            "ErrorLoadRetry": "An error occured while loading, please retry.",
            "ErrorLoad": "An error occured while loading.",
            "SuccessSendPasswordReq": "The password reset request has been send. Check your email.",
            "AbsenceNewRequest": "New request",
            "AbsenceCurrent": "Current leaves",
            "History": "History",
            "AwaitingValidation": "Awaiting validations (%s)",
            "AwaitingValid": "Await. validations (%s)",
            "ToValidate": "To validate (%s)",
            "Schedule": "Schedule",
            "CurrentRequests": "Current requests",
            "RequestToValidate": "Requests to validate",
            "Remark": "Remark",
            "StartTime": "From time",
            "EndTime": "Until time",
            "StartDate": "From date",
            "EndDate": "Until date",
            "AllDay": "All day",
            "Morning": "Morning",
            "Afternoon": "Evening",
            "TimeSlot": "Time slot",
            "VerifyYourEntry": "Please check your input.",
            "FromSendError": "An error occured while sending the form.",
            "RequestSended": "Request sended",
            "NewRequest": "New request",
            "SendRequest": "Send request",
            "Delete": "Delete",
            "Cancel": "Cancel",
            "AbsenceRequestDenied": "Request denied",
            "AbsenceRequestSended": "Request sended",
            "AbsenceRequestValidated": "Request validated",
            "AbsenceRequestCanceled": "Request canceled",
            "AbsenceRequestBeingValidated": "Request being validated",
            "Accept": "Accept",
            "Deny": "Deny",
            "From": "from",
            "Until": "until",
            "ValidationSended": "Validation sended.",
            "Confirmation": "Confirmation",
            "YouAreAbleToCommentYourDecision": "You have the possibility to comment your decision.",
            "Validate": "Validate",
            "AreYouSureToCancelThisRequest": "Are you sure, would you like to cancel this request ?",
            "WorkCenter": "WorkCenter",
            "Department": "Department",
            "AbsenceRequestDate": "Request date",
            "Cancellation": "CANCELLATION - ",
            "Current": "Current",
            "Request": "Request",
            "Employee": "Employee",
            "Status": "Status",
            "LanguageItself": "Language",
            "NewAbsenceRequest" : "New request",
        }
    },
    fr: {
        translation: {
            "Welcome to React": "Bienvenue à React et react-i18next",
            "News": "Actualités",
            "Home": "Accueil",
            "HoursBalance": "Soldes heures",
            "Hours": "Heures",
            "HRDocuments": "Documents RH",
            "Documents": "Documents",
            "SalaryDocument": "Documents salaires",
            "PersonalDocuments": "Documents personnels",
            "TimeOff": "Absences / Vacances",
            "MyAccount": "Mon compte",
            "MyProfile": "Mon profil",
            "ChangePassword": "Modifier mon mot de passe",
            "MyEmployeeFile": "Ma fiche employé",
            "Preferences": "Préférences",
            "DefaultContract": "Contrat par défaut",
            "ErrorRetry": "Une erreur est survenue, veuillez réessayer.",
            "Logout": "Déconnexion",
            "MainFooterText1": "©2022 BMC Société de Services Informatiques SA",
            "MainFooterText2": " - Powered by Strategic Web.",
            "Title": "Titre",
            "FirstName": "Prénom",
            "LastName": "Nom",
            "NameComplement": "Complément de nom",
            "Address": "Adresse",
            "Address2": "Adresse 2",
            "ZipCode": "NPA",
            "City": "Ville",
            "Country": "Pays",
            "BirthDate": "Date de naissance",
            "MobilePhone": "Téléphone mobile",
            "Phone": "Téléphone",
            "Email": "Email",
            "Loading": "Chargement...",
            "Refresh": "Actualiser",
            "Type": "Type",
            "Salary": "Salaire",
            "Certificate": "Certificat",
            "Year": "Année",
            "Period": "Période",
            "All": "Tout",
            "Alls": "Tous",
            "NoData": "Pas de données disponibles.",
            "Code": "Code",
            "ActivityType": "Type d'absence",
            "Balance": "Solde",
            "Contract": "Contrat",
            "None": "Aucun",
            "ErrorLoadRetry": "Une erreur est survenue lors du chargement, veuillez ressayer.",
            "ErrorLoad": "Une erreur est survenue lors du chargement.",
            "SuccessSendPasswordReq": "La requête de réinitialisation de mot de passe a été envoyée. Vérifiez votre boîte mail.",
            "AbsenceNewRequest": "Nouvelle demande",
            "AbsenceCurrent": "Absences en cours",
            "History": "Historique",
            "AwaitingValidation": "Validations en attente (%s)",
            "ToValidate": "À valider (%s)",
            "AwaitingValid": "Valid. en attente (%s)",
            "Schedule": "Planning",
            "CurrentRequests": "Demandes en cours",
            "RequestToValidate": "Demandes à valider",
            "Remark": "Remarque",
            "StartTime": "Heure de début",
            "EndTime": "Heure de fin",
            "StartDate": "Date de début",
            "EndDate": "Date de fin",
            "AllDay": "Toute la journée",
            "Morning": "Matin",
            "Afternoon": "Après-midi",
            "TimeSlot": "Plage horaire",
            "VerifyYourEntry": "Veuillez vérifier votre saisie.",
            "FromSendError": "Une erreur s'est produite lors de l'envoi.",
            "RequestSended": "Demande envoyée.",
            "NewRequest": "Nouvelle demande",
            "SendRequest": "Envoyer la demande",
            "Delete": "Effacer",
            "Cancel": "Annuler",
            "AbsenceRequestDenied": "Demande refusée",
            "AbsenceRequestSended": "Demande transmise",
            "AbsenceRequestValidated": "Demande validée",
            "AbsenceRequestCanceled": "Demande annulée",
            "AbsenceRequestBeingValidated": "Demande en cours de validation",
            "Accept": "Accepter",
            "Deny": "Refuser",
            "From": "de",
            "Until": "à",
            "Confirmation": "Confirmation",
            "ValidationSended": "La validation a bien été prise en compte.",
            "YouAreAbleToCommentYourDecision": "Vous avez la possibilité d'indiquer les raisons de votre décision : ",
            "Validate": "Valider",
            "AreYouSureToCancelThisRequest": "Voulez-vous vraiment annuler cette demande ?",
            "WorkCenter": "Centre de production",
            "Department": "Département",
            "AbsenceRequestDate": "Date demande",
            "Cancellation": "ANNULATION - ",
            "Current": "En cours",
            "Request": "Demande",
            "Employee": "Employé",
            "Status": "Statut",
            "LanguageItself": "Langue",
            "NewAbsenceRequest": "Nouvelle demande d'absence",
        }
    }
} as const;

const DETECTION_OPTIONS = {
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',

    // cache user language
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'],
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        detection: DETECTION_OPTIONS,
        resources: resources,
        fallbackLng: (window as any).Config.DefaultLanguage,
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ',',
            format(value, format) {
                if (format === 'uppercase') return value.toUpperCase();
                return value;
            },
        },

        react: {
            defaultTransParent: 'div',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        },
    });

/*
 * 
 *     .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });
*/

export default i18n;

declare module 'react-i18next' {
    interface CustomTypeOptions {
        defaultNS: 'translation';
        resources: typeof resources['en'];
    }

    /*type DefaultResources = typeof resources['en'];
    interface Resources extends DefaultResources { }*/
}