import * as React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Grid from '@material-ui/core/Grid';
import HRDocumentTile from './HRDocumentTile';
import PersoDocumentTile from './PersoDocumentTile';
import SalaryDocumentTile from './SalaryDocumentTile';
import TimePeriodBalanceTile from './TimePeriodBalanceTile';
import AbsenceRequestTile from './absences/AbsenceRequestTile';
import HRNewsTile from './HRNewsTile';
import { useStyles } from '../common/styles';
import { checkModuleActive } from '../common/utils';

export default function Home() {

    const { isAuthenticated } = useAuth0();

    const xsSize = 12;
    const mdSize = 6;
    const lgSize = 4;
    const classes = useStyles();
    return (
        <div className={classes.topContainer}>
            {
                isAuthenticated && (
                    <div>
                        <Grid container spacing={0} style={{ padding: 0 }} className={classes.gridForm} justify="flex-start">
                            {checkModuleActive('News') && (
                                <Grid item xs={xsSize} sm={mdSize} lg={lgSize} className={classes.homeTile}>
                                    <HRNewsTile />
                                </Grid>)}
                            {checkModuleActive('TimeBalance') && (
                                <Grid item xs={xsSize} sm={mdSize} lg={lgSize} className={classes.homeTile}>
                                    <TimePeriodBalanceTile />
                                </Grid>)}
                            {checkModuleActive('HRDocuments') && (
                                <Grid item xs={xsSize} sm={mdSize} lg={lgSize} className={classes.homeTile}>
                                    <HRDocumentTile />
                                </Grid>)}
                            {checkModuleActive('SalaryDocuments') && (
                                <Grid item xs={xsSize} sm={mdSize} lg={lgSize} className={classes.homeTile}>
                                    <SalaryDocumentTile />
                                </Grid>)}
                            {checkModuleActive('PersonalDocuments') && (
                                <Grid item xs={xsSize} sm={mdSize} lg={lgSize} className={classes.homeTile}>
                                    <PersoDocumentTile />
                                </Grid>)}
                            {checkModuleActive('Absences') && (
                                <Grid item xs={xsSize} sm={mdSize} lg={lgSize} className={classes.homeTile}>
                                    <AbsenceRequestTile />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                )}
        </div>
    );
}