import * as React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { CardContent, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../common/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { httpHeader, handleFetchErrors, getApiUrl, downloadDocument } from '../common/utils';
import { SessionContext } from './../App';
import * as rdd from 'react-device-detect';

export default function HRDocumentPersoTile() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [documents, setDocuments] = React.useState([]);
    const history = useHistory();
    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;

    /*const fetchInfos = async () => {
        await fetchContract();
        await loadNextPage(initialQueryState);
    };
    fetchInfos();*/

    React.useEffect(() => {//when logged in
        const fetchInfos = async () => {
            if (isAuthenticated && state.AddressId && state.AddressId > 0) {
                var httpCmd;
                await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {

                    httpCmd = httpHeader('GET', token);

                }).catch(e => console.log(e));

                var docs: any = [];

                await fetch(getApiUrl((window as any).Config, 'hrportal') + '/salarydocument?type=payslip&addressid=' + state.AddressId + '&contractid=' + state.ContractId + '&pageno=0&pagesize=1', httpCmd)
                    .then(res => handleFetchErrors(res).json())
                    .then(ret => {
                        //console.log(ret);
                        docs = docs.concat(ret.documents);
                    }).catch(e => console.log(e));

                await fetch(getApiUrl((window as any).Config, 'hrportal') + '/salarydocument?type=salarystatement&addressid=' + state.AddressId + '&contractid=' + state.ContractId + '&pageno=0&pagesize=1', httpCmd)
                    .then(res => handleFetchErrors(res).json())
                    .then(ret => {
                        //console.log(ret);
                        docs = docs.concat(ret.documents);
                    }).catch(e => console.log(e));

                await setDocuments(docs);
            };
        }
        fetchInfos();
    }, [isAuthenticated, state, state.AddressId, state.ContractId]);

    const downloadSalaryDoc = async function (fileGuid: string, docType: number) {
        var type = '';
        if (docType === 1)
            type = 'payslip';
        else
            type = 'salarystatement';

        await downloadDocument(
            fileGuid,
            '/salarydocument/' + fileGuid + '?type=' + type + '&addressid=' + state.AddressId + '&contractid=' + state.ContractId,
            rdd.isSafari ? window.open() : null, //Pour bizarrerie Safari 25.08.21 / JMA Ticket 154222
            getAccessTokenSilently
        ).catch(e => { alert(t('ErrorLoad')) });
    }


    return (
        <Card className={classes.tileCard} onClick={() => { history.push(process.env.PUBLIC_URL + "/salarydocument"); }}>
            <CardContent>
                <Typography className={classes.tileTitle}><FontAwesomeIcon icon="search-dollar" size="2x" fixedWidth className={classes.icons} /><span className={classes.tileTitleText}>{t("SalaryDocument")}</span></Typography>
                <Divider />
                <ul>
                    {
                        documents.map((doc: any) => {
                            if ('title' in doc)
                                return (<li key={doc.guid} className={clsx(classes.tileElement, classes.liHover)} onClick={(event) => { event.stopPropagation(); downloadSalaryDoc(doc.guid, doc.type) }}>{doc.title}</li>);
                            else
                                return null;
                        })
                    }
                </ul>
            </CardContent>
        </Card>
    );
}