import * as React from 'react';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { CardContent, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../common/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { httpHeader, handleFetchErrors, getApiUrl, getIconByFileName, convertToAuth0Language, checkModuleActive } from '../common/utils';
import { SessionContext } from './../App';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { downloadDocument } from '../common/utils';
import * as rdd from 'react-device-detect';

export default function HRDocumentPerso() {
    const QTY_BY_PAGE = 20;

    const { t } = useTranslation();
    const classes = useStyles();
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [documents, setDocuments] = React.useState([]);
    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;

    const noContract = { id: 0, contractNumber: t('None'), hrPortalDefault: false };
    const [contract, setContract] = React.useState('0');
    const [contracts, setContracts] = React.useState([noContract]);
    const initialQueryState = { url: '', pageNo: 0, allLoad: false };

    const [query, setQuery] = React.useState(initialQueryState);
    const [isSearching, setIsSearching] = React.useState(false);

    window.onscroll = () => {
        if (document.documentElement.scrollHeight - (document.documentElement.offsetHeight / 2) <= document.documentElement.scrollTop + document.documentElement.offsetHeight) {
            loadNextPage({ ...query, pageNo: query.pageNo + 1 });
        }
    }

    async function fetchContract() {
        if (isAuthenticated && state.AddressId && state.AddressId > 0) {
            setDocuments([]);

            let httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {

                httpCmd = httpHeader('GET', token);
            }).catch(e => console.log(e));

            await fetch(getApiUrl((window as any).Config, 'hrportal') + '/currentemployee/contracts', httpCmd)
                .then(res => handleFetchErrors(res).json())
                .then(ret =>
                    updateContracts(ret)
                )
                .catch(e => console.log(e));
        }
    }

    function getContractId() {
        return parseInt(contract) === 0 ? state.ContractId : parseInt(contract);
    }

    const loadNextPage = async (newQuery: any) => { 
        if (isSearching) return;
        if (newQuery.allLoad) return;

        setQuery(newQuery);
        try {

            setIsSearching(true);
            var httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('GET', token);
            }).catch(e => console.log(e));

            try {
                var ctrId = getContractId();

                var res = await fetch(getApiUrl((window as any).Config, 'hrportal') + '/document?type=personal&addressid=' + state.AddressId + '&contractid=' + ctrId + '&pageno=' + newQuery.pageNo + '&pagesize=' + QTY_BY_PAGE, httpCmd)

                var text = await handleFetchErrors(res).text();

                if (text.length > 0) {
                    var json = JSON.parse(text);

                    if (newQuery.pageNo === 0)
                        setDocuments(json.documents);
                    else
                        setDocuments(documents.concat(json.documents));
                }
                else {
                    if (newQuery.pageNo === 0)
                        setDocuments([])
                    await setQuery(query => ({ ...query, allLoad: true })) //tout est loadé !
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        finally {
            setIsSearching(false);
        };
    }
    React.useEffect(() => {//when logged in
        if (isAuthenticated) {
            const fetchInfos = async () => {
                if (parseInt(contract) < 1)
                    await fetchContract();
                await loadNextPage(initialQueryState);
            };
            fetchInfos();
        }
        else
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });

    }, [isAuthenticated, state, contract]);

    const downloadPersoDoc = async function (fileGuid: string, contractId: number) {
        await downloadDocument(
            fileGuid,
            '/document/' + fileGuid + '?type=personal&addressid=' + state.AddressId + '&contractid=' + contractId,
            rdd.isSafari ? window.open() : null, //Pour bizarrerie Safari 25.08.21 / JMA Ticket 154222
            getAccessTokenSilently
        ).catch(e => { alert(t('ErrorLoad')) });
    }

    const updateContracts = (ret: any) => {
        //console.log(ret);
        var selected = ret.contracts.filter((c: any) => c.hrPortalDefault);

        setContracts(a => [...a.filter(a => a.id !== 0), noContract]);
        if (selected.length === 0)
            ret.contracts.unshift(noContract);

        //console.log(ret);
        if (Array.isArray(selected) && selected.length > 0) {
            setContract(selected[0].id);
            if (ret.contracts[0].id === 0)
                ret.contracts.shift();
            setContracts(ret.contracts);
        }
        else {
            setContracts(ret.contracts);
        }
    }

    const handleChange = React.useCallback(async (event) => {
        if (isAuthenticated && event.target.value > 0) {
            setContract(event.target.value);
        }
    }, [isAuthenticated]);

    if (!checkModuleActive('PersonalDocuments')) //Hidden module in config
        return (<></>);

    return (
        <Container maxWidth="md" className={classes.centeredTopContainer}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography><FontAwesomeIcon icon="user-lock" size="2x" fixedWidth className={classes.icons} /><span className={classes.pageTitleText}>{t("PersonalDocuments")}</span></Typography>
                    <Divider className={classes.pageTitleDivider} />
                    <Grid container>
                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6} style={{ marginBottom: '20px' }}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="contract-select-outlined-label" >{t("DefaultContract")}</InputLabel>
                                <Select
                                    labelId="contract-select-outlined-label"
                                    id="contract-select-select-outlined"
                                    value={contract}
                                    onChange={handleChange}
                                    label={t("DefaultContract")}
                                    displayEmpty
                                >
                                    {
                                        contracts.map((ctr: any) => {
                                            if ('id' in ctr && 'contractNumber' in ctr)
                                                return (<MenuItem key={ctr.id} value={ctr.id}>{ctr.contractNumber}</MenuItem>);
                                            else
                                                return null;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {
                        documents.map((doc: any) => {
                            if ('title' in doc)
                                return (<Grid container style={{ width: '100%' }} key={doc.guid} className={classes.tileCard} onClick={() => { downloadPersoDoc(doc.guid, getContractId()) }}>
                                    <Grid item xs={2} sm={2} lg={1}>
                                        <FontAwesomeIcon icon={getIconByFileName(doc.fileName)} size="2x" fixedWidth title={doc.fileName} />
                                    </Grid>
                                    <Grid item xs={10} sm={10} lg={11}><Typography variant="h6">{doc.title}</Typography>
                                        <Typography className={classes.title}>{doc.description}</Typography>
                                    </Grid>
                                </Grid>);
                            else
                                return null;
                        })
                    }
                    {(document.documentElement.scrollHeight - 50 <= document.documentElement.clientHeight) && !query.allLoad && documents.length % QTY_BY_PAGE === 0 && (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><Button variant="contained" color="default" size="medium" onClick={() => { loadNextPage({ ...query, pageNo: query.pageNo + 1 }); }}>
                            {t("Loading")}
                        </Button></div>
                    )}
                    </CardContent>
            </Card>
        </Container>
    );
}