import * as React from 'react';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { CardContent, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../common/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { httpHeader, handleFetchErrors, getApiUrl, convertToAuth0Language, checkModuleActive } from '../common/utils';
import { SessionContext } from './../App';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function TimePeriodBalance() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [balances, setBalances] = React.useState([]);
    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;

    const noContract = { id: 0, contractNumber: t('None'), hrPortalDefault: false };
    const [contract, setContract] = React.useState('0');
    const [contracts, setContracts] = React.useState([noContract]);
    const [isSearching, setIsSearching] = React.useState(false);

    window.onscroll = () => { }

    async function fetchContract() {
        if (isAuthenticated && state.AddressId && state.AddressId > 0) {
            setBalances([]);

            let httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {

                httpCmd = httpHeader('GET', token);
            }).catch(e => console.log(e));

            await fetch(getApiUrl((window as any).Config, 'hrportal') + '/currentemployee/contracts', httpCmd)
                .then(res => handleFetchErrors(res).json())
                .then(ret =>
                    updateContracts(ret)
                )
                .catch(e => console.log(e));
        }
    }

    const loadHeading = async () => {
        if (isSearching) return;
        try {
            setIsSearching(true);
            var httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('GET', token);
            }).catch(e => console.log(e));

            try {
                var ctrId = parseInt(contract) === 0 ? state.ContractId : parseInt(contract);

                var res = await fetch(getApiUrl((window as any).Config, 'hrportal') + '/timeperiodbalance?selection=detailedview&addressid=' + state.AddressId + '&contractid=' + ctrId, httpCmd);

                var text = await handleFetchErrors(res).text();
                //console.log(text);

                if (text.length > 0) {
                    var json = JSON.parse(text);

                    setBalances(json.balances);
                }
                else {
                    setBalances([]);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        finally {
            setIsSearching(false);
        };
    }

    React.useEffect(() => {//when logged in
        if (isAuthenticated) {
            const fetchInfos = async () => {
                if (parseInt(contract) < 1)
                    await fetchContract();
                await loadHeading();
            };
            fetchInfos();
        }
        else
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
    }, [isAuthenticated, contract, state]);


    const updateContracts = (ret: any) => {
        //console.log(ret);
        var selected = ret.contracts.filter((c: any) => c.hrPortalDefault);

        setContracts(a => [...a.filter(a => a.id !== 0), noContract]);
        if (selected.length === 0)
            ret.contracts.unshift(noContract);

        //console.log(ret);
        if (Array.isArray(selected) && selected.length > 0) {
            setContract(selected[0].id);
            if (ret.contracts[0].id === 0)
                ret.contracts.shift();
            setContracts(ret.contracts);
        }
        else {
            setContracts(ret.contracts);
        }
    }

    const handleChange = React.useCallback(async (event) => {
        if (isAuthenticated && event.target.value > 0) {
            setContract(event.target.value);
        }
    }, [isAuthenticated]);

    if (!checkModuleActive('TimeBalance')) //Hidden module in config
        return (<></>);

    return (
        <Container maxWidth="md" className={classes.centeredTopContainer}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography><FontAwesomeIcon icon="user-clock" size="2x" fixedWidth className={classes.icons} /><span className={classes.pageTitleText}>{t("HoursBalance")}</span></Typography>
                    <Divider className={classes.pageTitleDivider} />
                        <Grid container>
                            <Grid item lg={3} md={3} xl={3}>&nbsp;</Grid>
                            <Grid item xs={12} sm={12} lg={6} md={6} xl={6} style={{ marginBottom: '20px', alignContent: 'center' }}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="contract-select-outlined-label" >{t("DefaultContract")}</InputLabel>
                                    <Select
                                        labelId="contract-select-outlined-label"
                                        id="contract-select-select-outlined"
                                        value={contract}
                                        onChange={handleChange}
                                        label={t("DefaultContract")}
                                        displayEmpty
                                    >
                                        {
                                            contracts.map((ctr: any) => {
                                                if ('id' in ctr && 'contractNumber' in ctr)
                                                    return (<MenuItem key={ctr.id} value={ctr.id}>{ctr.contractNumber}</MenuItem>);
                                                else
                                                    return null;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    <Grid container>
                        <Grid item lg={3} md={3} xl={3}>&nbsp;</Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6} style={{ marginBottom: '20px', alignContent: 'center' }}>
                        <table style={{ paddingTop: '5px', borderSpacing: '10px'}}>
                            <tbody>
                                    <tr><td><Typography variant="h6">{t("Code")}</Typography></td><td style={{width:'100%'}}><Typography variant="h6">{t("ActivityType")}</Typography></td><td>&nbsp;&nbsp;&nbsp;</td><td><Typography variant="h6" style={{ textAlign: 'right', width: '100%' }}>{t("Balance")}</Typography></td></tr>
                                <tr><td colSpan={4}><Divider /></td></tr>
                                {
                                    (balances) && (
                                        balances.map((bal: any) => {
                                            return (<tr key={bal.id}><td>{bal.code}</td><td>{bal.text}</td><td></td><td style={{ textAlign: 'right' }}><b>{bal.balance.toFixed(2)}</b></td></tr>);
                                        }))
                                }
                                {
                                    (!balances || (Array.isArray(balances) && balances.length === 0)) && (

                                        <tr><td colSpan={4}>{t("NoData")}</td></tr>
                                    )
                                }
                                </tbody></table>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
}