import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../common/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { httpHeader, handleFetchErrors, getApiUrl, convertToAuth0Language, checkModuleActive } from '../../common/utils';
import { SessionContext } from './../../App';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TransitionProps } from '@material-ui/core/transitions';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CurrentAbsenceRequests(props: any) {
    const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const classes = useStyles();
    const { t } = useTranslation();

    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;

    const [isSearching, setIsSearching] = React.useState(false);
    const [absences, setAbsences] = React.useState([]);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [remark, setRemark] = React.useState('');

    const [errorSendForm, setErrorSendForm] = React.useState(false);
    const [formSended, setFormSended] = React.useState(false);

    const noDepartment = { id: '0', code: t('Alls'), text: t('Alls') };
    const [Departments, setDepartments] = React.useState([noDepartment]);

    const noWorkCenter = { id: '0', code: t('Alls'), text: t('Alls') };
    const [WorkCenters, setWorkCenters] = React.useState([noWorkCenter]);

    const formDataInitialState = {
        DepartmentId: '0', WorkCenterId: '0',
    }
    const [formData, setFormData] = React.useState(formDataInitialState);

    const handleClickOpen = async () => {
        await setFormSended(false);
        await setErrorSendForm(false);
        await setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleDecision = async () => {
        await sendRequest();
        await setRemark('');
        await setOpenDialog(false);
        await window.location.reload();
        //loadAll();
    };

    const handleDepartments = React.useCallback(async (event) => {
        if (isAuthenticated && event.target.value > -1) {
            setFormData(inputs => ({ ...inputs, 'DepartmentId': event.target.value }));
        }
    }, [isAuthenticated]);

    const handleWorkCenters = React.useCallback(async (event) => {
        if (isAuthenticated && event.target.value > -1) {
            setFormData(inputs => ({ ...inputs, 'WorkCenterId': event.target.value }));
        }
    }, [isAuthenticated]);

    const sendRequest = async () => {
        try {
            const AbsenceRequestValidation = {
                ContractId: state.ContractId,
                AddressId: state.AddressId,
                AbsenceRequestId: lastId,
                ValidatorRemark: remark,
                Decision: lastDecision
            };

            let httpCmd: RequestInit | null = null;
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('PUT', token);
            });


            if (httpCmd) {
                let bodyRq = JSON.stringify(AbsenceRequestValidation);
                (httpCmd as RequestInit).body = bodyRq;

                //console.log(bodyRq);

                var res = await fetch(getApiUrl((window as any).Config, 'hrportal') + '/absencevalidation/validaterequest', httpCmd);

                var text = await handleFetchErrors(res).text();
                //console.log(text);

                if (text.length > 0) {
                    console.log(text)
                }
            }
            setRemark('');
            setFormSended(true);
            setErrorSendForm(false);
            loadAll();
            //console.log(formData);
        }
        catch (e) {
            console.log(e);
            setErrorSendForm(true);
        }
    }

    const handleRemarkChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //persist the info before calling an async fct.
        const target = event.currentTarget;
        const value = target.value;
        const name = target.name;
        const type = target.type;

        if (event !== null && event.target !== null && name !== null) {
            setRemark(value);
        }
    };

    const [lastDecision, setLastDecision] = React.useState(0);
    const [lastId, setLastId] = React.useState('');

    async function loadAll() {
        if (isSearching) return;
        if (!state.IsValidateManager) return;

        try {
            setIsSearching(true);
            setAbsences([]);
            var httpCmd = {};
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('GET', token);
            }).catch(e => console.log(e));

            await fetch(getApiUrl((window as any).Config, 'hrportalparameters') + '/lookup/departments', httpCmd)
                .then(res => handleFetchErrors(res).json())
                .then(ret => {
                    setDepartments([...ret.lstDepartments, noDepartment]);
                    setFormData(inputs => ({ ...inputs, 'WorkCenterId': '0' }));
                })
                .catch(e => console.log(e));

            try {
                var queryend = '';
                queryend += parseInt(formData.DepartmentId) > 0 ? '&departmentid=' + formData.DepartmentId : '';
                queryend += parseInt(formData.WorkCenterId) > 0 ? '&workcenterid=' + formData.WorkCenterId : '';

                var res = await fetch(getApiUrl((window as any).Config, 'hrportal') + '/absencevalidation/validatablerequests?addressid=' + state.AddressId + '&contractid=' + state.ContractId + queryend, httpCmd)

                var text = await handleFetchErrors(res).text();

                if (text.length > 0) {
                    var json = JSON.parse(text);
                    setAbsences(json.absenceRequests);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        finally {
            setIsSearching(false);
        };
    }

    async function loadWorkCenters() {
        var httpCmd = {};

        await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
            httpCmd = httpHeader('GET', token);
        }).catch(e => loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL }));

        await fetch(getApiUrl((window as any).Config, 'hrportalparameters') + '/lookup/workcenters?departmentid=' + formData.DepartmentId, httpCmd)
            .then(res => handleFetchErrors(res).json())
            .then(ret => {
                setWorkCenters([...ret.lstWorkCenters, noDepartment]);
            })
            .catch(e => console.log(e));
    }


    React.useEffect(() => {
        if (!isAuthenticated)
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
        else
            if (state.AddressId && state.AddressId > 0) {
                loadWorkCenters();
            }
    }, [isAuthenticated, state, formData.DepartmentId]);

    React.useEffect(() => {
        if (!isAuthenticated)
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
        else
            if (state.AddressId && state.AddressId > 0) {
                loadAll();
            }
    }, [isAuthenticated, state, formData.DepartmentId, formData.WorkCenterId]);

    if (!checkModuleActive('Absences')) //Hidden module in config
        return (<></>);

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="contract-select-outlined-label" >{t("Department")}</InputLabel>
                        <Select
                            labelId="contract-select-outlined-label"
                            id="contract-select-select-outlined"
                            value={formData.DepartmentId}
                            onChange={handleDepartments}
                            label={t("Department")}
                            displayEmpty
                        >
                            {
                                Departments.map((department: any) => {
                                    if ('id' in department)
                                        return (<MenuItem key={department.id} value={department.id}>{department.text}</MenuItem>);
                                    else
                                        return null;
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="contract-select-outlined-label" >{t("WorkCenter")}</InputLabel>
                        <Select
                            labelId="contract-select-outlined-label"
                            id="contract-select-select-outlined"
                            value={formData.WorkCenterId}
                            onChange={handleWorkCenters}
                            label={t("WorkCenter")}
                            displayEmpty
                        >
                            {
                                WorkCenters.map((workcenter: any) => {
                                    if ('id' in workcenter)
                                        return (<MenuItem key={workcenter.id} value={workcenter.id}>{workcenter.text}</MenuItem>);
                                    else
                                        return null;
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Collapse in={errorSendForm}>
                <Alert severity="error">{t("FromSendError")}</Alert>
            </Collapse>
            <Collapse in={formSended}>
                <Alert severity="success">{t("ValidationSended") }</Alert>
            </Collapse>
            {
                absences.map((abs: any, index) => {
                    var dateStart = new Date(abs.absenceRequest.dateFrom)
                    var dateEnd = new Date(abs.absenceRequest.dateTo)
                    var dateRequest = new Date(abs.valueDate)

                    var period = ''
                    switch (abs.absenceRequest.period) {
                        case 2:
                            period = t("Morning");
                            break;
                        case 3:
                            period = t("Afternoon");
                            break;
                        case 4:
                            var timeStart = new Date(abs.absenceRequest.timeFrom)
                            var timeEnd = new Date(abs.absenceRequest.timeTo)

                            period = t("TimeSlot") + ' ' + t("From") + ' ' + timeStart.getHours() + ':' + timeStart.getMinutes().toString().padStart(2, '0') + ' ' + t("Until") + ' ' + timeEnd.getHours() + ':' + timeEnd.getMinutes().toString().padStart(2, '0');
                            break;
                    }

                    var fstyle = 'normal';
                    if (abs.type === 2)
                        fstyle = 'italic';

                    if ('id' in abs)
                        return (
                            <div key={abs.id} style={{ marginTop: '8px', marginBottom: '8px', fontStyle: fstyle }}>
                                <Grid container spacing={1} className={classes.tileCard}>
                                    <Grid item xs={12} sm={6} lg={6}>
                                        <Typography className={classes.title}><b>
                                        {(abs.type === 2) && (
                                            t("Cancellation")
                                            )}{abs.employeeAdName} ({abs.employementContractNumber} / {abs.employeeOccupancyRate}%) - {abs.absenceRequest.activityType.text}
                                        </b></Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={6}>
                                        <span>{abs.department.text} {(abs.workCenter !== null) && '/'} {abs.workCenter?.text}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={3} lg={3}>
                                        {t("StartDate")} : <b>{new Intl.DateTimeFormat('fr-CH').format(dateStart)}</b>
                                    </Grid>
                                    <Grid item xs={12} sm={3} lg={3}>
                                        {t("EndDate")} : <b>{new Intl.DateTimeFormat('fr-CH').format(dateEnd)}</b>
                                    </Grid>
                                    <Grid item xs={10} sm={4} lg={4}>
                                        {t("AbsenceRequestDate")} : <b>{new Intl.DateTimeFormat('fr-CH').format(dateRequest)}</b>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2}>
                                        <FontAwesomeIcon icon={['far', 'check-circle']} size="2x" title={t("Accept")} color="darkgreen" fixedWidth onClick={() => { setLastDecision(2); setLastId(abs.id); handleClickOpen(); }} />
                                        <FontAwesomeIcon icon={['far', 'times-circle']} size="2x" title={t("Deny")} color="red" fixedWidth onClick={() => { setLastDecision(3); setLastId(abs.id); handleClickOpen(); }} />
                                    </Grid>
                                    {
                                        (period) &&
                                        <Grid item xs={12} sm={12} lg={12}>
                                            {period}
                                        </Grid>
                                    }
                                    {
                                        (abs.absenceRequest.applicantRemark) &&
                                        <Grid item xs={12} sm={12} lg={12}>
                                            {t("Remark")} : {abs.absenceRequest.applicantRemark}
                                        </Grid>
                                    }
                                </Grid>
                                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                            </div>
                        );
                    else
                        return null;
                })
            }
            {(absences.length === 0 && isSearching) && (
                <div style={{ width: '100%', textAlign: 'center' }}><CircularProgress /></div>
            )}
            {(absences.length === 0 && !isSearching) && (
                <div style={{ width: '100%', textAlign: 'center' }}>{t("NoData")}</div>
            )}
            <Dialog open={openDialog} onClose={handleClose} TransitionComponent={Transition} keepMounted aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t("Confirmation")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("YouAreAbleToCommentYourDecision")}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="remark-label-placeholder"
                        label={t("Remark")}
                        variant="outlined"
                        onChange={handleRemarkChange}
                        fullWidth
                        value={remark}
                        inputProps={{
                            name: 'Remark',
                            id: 'remark-label-placeholder',
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={handleDecision} color="primary">
                        {t("Validate")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}