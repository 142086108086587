import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { httpHeader, handleFetchErrors, getApiUrl, convertToAuth0Language, checkModuleActive } from '../../common/utils';
import { useStyles } from '../../common/styles';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from "@auth0/auth0-react";
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CardContent, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { SessionContext } from './../../App';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import * as rdd from 'react-device-detect';

export default function NewAbsenceRequest(props: any) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const noAbsenceType = { id: 0, code: t('None'), text: t('None') };
    const [absenceTypes, setAbsenceTypes] = React.useState([noAbsenceType]);

    const handleAbsenceTypes = React.useCallback(async (event) => {
        if (isAuthenticated && event.target.value > 0) {
            setFormData(inputs => ({ ...inputs, 'AbsenceTypeId': event.target.value }));
        }
    }, [isAuthenticated]);

    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;

    const IsInputDateSupported = false;//Desactiv� car pas n�cessaire !rdd.isFirefox && !rdd.isSafari;
    const IsInputTimeSupported = !(rdd.isFirefox || (rdd.isMacOs && rdd.isSafari))

    const formDataInitialState = {
        AbsenceTypeId: '0', StartDateLeave: (null as Date | null), EndDateLeave: (null as Date | null), PeriodTypeEnum: '', StartTimeLeave: (null as Date | null), EndTimeLeave: (null as Date | null), Remark: ''
    }

    const [formData, setFormData] = React.useState(formDataInitialState);
    const [formValid, setFormValid] = React.useState(false);
    const [errorSendForm, setErrorSendForm] = React.useState(false);
    const [formSended, setFormSended] = React.useState(false);
    const [formPeriodTypeEnumDisabled, setFormPeriodTypeEnumDisabled] = React.useState(true);
    const [lastException, setLastException] = React.useState('');

    const [formState, setFormState] = React.useState({
        ActivityTypeError: false, StartDateLeaveError: false, EndDateLeaveError: false, PeriodTypeEnumError: false, StartTimeLeaveError: false, EndTimeLeaveError: false
    })

    async function fetchAbsenceTypes() {
        let httpCmd = {};
        await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
            httpCmd = httpHeader('GET', token);
        }).catch(e => console.log(e));

        await fetch(getApiUrl((window as any).Config, 'hrportalparameters') + '/lookup/activitytypes', httpCmd)
            .then(res => handleFetchErrors(res).json())
            .then(ret => {
                setAbsenceTypes([...ret.lstActivityTypes, noAbsenceType]);
            })
            .catch(e => console.log(e));
    }

    const handleFormChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //persist the info before calling an async fct.
        const target = event.currentTarget;
        const value = target.value;
        const name = target.name;
        const type = target.type;

        //console.log(target + ' ' + value + ' ' + name + ' ' + type)

        if (type.toLowerCase() === 'checkbox') {
            const checked = (target as HTMLInputElement).checked;
            if (event !== null && event.target !== null && name !== null) {
                //console.log(name + '  ' + checked);
                setFormData(inputs => ({ ...inputs, [name]: checked }));
            }
        }
        else {
            if (event !== null && event.target !== null && name !== null) {
                //console.log(name + '  ' + value);
                setFormData(inputs => ({ ...inputs, [name]: value }));
            }
        }

    };

    const handleDateChange = (date: Date | null, name: string) => {
        if (date) {
            setFormData(inputs => ({ ...inputs, [name]: date }));
        }
    };

    React.useEffect(() => {//when logged in
        if (isAuthenticated) {
            const fetchInfos = async () => {
                if (parseInt(formData.AbsenceTypeId) < 1)
                    await fetchAbsenceTypes();
            };
            fetchInfos();
        }
    }, [isAuthenticated]);

    React.useEffect(() => {//when form state change
        let IsFormValid = formData.AbsenceTypeId !== '0' && formData.PeriodTypeEnum.length > 0;
        //console.log(IsFormValid);
        //console.log(formState);
        if (IsFormValid)
            for (let [key, value] of Object.entries(formState))
                if (value) {
                    IsFormValid = false;
                    //console.log(key);
                }
        //console.log(IsFormValid);
        setFormValid(IsFormValid);

    }, [formState]);

    const validateForm = () => {
        if (formData?.StartDateLeave !== null && formData?.StartDateLeave instanceof Date) {
            formData.StartDateLeave.setHours(0, 0, 0);
            formData.StartDateLeave.setMilliseconds(0);//for comparing
        }
        if (formData?.EndDateLeave !== null && formData?.EndDateLeave instanceof Date)
            formData.EndDateLeave.setHours(0, 0, 0);

        /*var startTimeMinutes = 0, endTimeMinutes = 0;

        if (formData?.StartTimeLeave !== null && formData?.EndTimeLeave !== null && formData?.StartTimeLeave instanceof Date && formData?.EndTimeLeave instanceof Date) {
            startTimeMinutes = formData.StartTimeLeave.getHours() * 60 + formData.StartTimeLeave.getMinutes();
            endTimeMinutes = formData.EndTimeLeave.getHours() * 60 + formData.EndTimeLeave.getMinutes();
        }

        console.log(startTimeMinutes + ' - ' + endTimeMinutes)*/

        var currentDate = new Date();
        currentDate.setHours(0, 0, 0);
        currentDate.setMilliseconds(0);

        var requestBackdateAllowed = (window as any).Config.Modules.Absences.RequestBackdateAllowed;

        setFormState(inputs => ({
            ...inputs, ['StartDateLeaveError']: formData?.StartDateLeave === null
                ? true
                : requestBackdateAllowed
                    ? false
                    : (formData.StartDateLeave as Date) < currentDate
        }));
        setFormState(inputs => ({ ...inputs, ['EndDateLeaveError']: formData?.EndDateLeave === null || (formData?.StartDateLeave !== null && formData?.StartDateLeave > formData?.EndDateLeave) }));
        setFormState(inputs => ({ ...inputs, ['ActivityTypeError']: formData.AbsenceTypeId.length === 0 || formData.AbsenceTypeId === '0' }));
        setFormState(inputs => ({ ...inputs, ['StartTimeLeaveError']: formData.PeriodTypeEnum.length > 0 && formData.PeriodTypeEnum === '4' && formData.StartTimeLeave === null }));
        setFormState(inputs => ({ ...inputs, ['EndTimeLeaveError']: (formData.PeriodTypeEnum.length > 0 && formData.PeriodTypeEnum === '4' && formData.EndTimeLeave === null) /*|| (formData?.StartTimeLeave !== null && startTimeMinutes > endTimeMinutes) */}));
    };


    React.useEffect(() => {//when form state change
        validateForm();
    }, [formData]);

    React.useEffect(() => {//when formData.PeriodTypeEnum state change
        setFormPeriodTypeEnumDisabled(formData.PeriodTypeEnum !== '4') //time only active when time range
    }, [formData.PeriodTypeEnum]);


    function addMinutes(date: Date, minutes: number) {
        return new Date(date.getTime() + minutes * 60000);
    }

    // FROM : https://stackoverflow.com/questions/141348/how-to-parse-a-time-into-a-date-object-from-user-input-in-javascript
    function parseTime(t: any) {
        var d = new Date();
        var time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
        d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
        d.setMinutes(parseInt(time[2]) || 0);
        return d;
    }

    const sendRequest = async () => {
        setErrorSendForm(false);
        try {
            const AbsenceRequestForm = {
                ContractId: props.ContractId, //don't use session ContractId since it's not updated
                AddressId: state.AddressId,
                AbsenceRequest: (null as any | null),
            };

            //console.log(formData.StartTimeLeave);
            
            var startTimeToLeave: Date | null = null;
            if (formData.StartTimeLeave !== null)
                if(formData.StartTimeLeave.toString() !== '') 
                    if ((formData.StartTimeLeave as any instanceof Date))
                        startTimeToLeave = (formData.StartTimeLeave as unknown as Date);
                    else
                        startTimeToLeave = parseTime(formData.StartTimeLeave);
            

            //console.log(formData.EndTimeLeave);

            var endTimeToLeave: Date | null = null;
            if (formData.EndTimeLeave !== null) 
                if (formData.EndTimeLeave.toString() !== '')
                    if ((formData.EndTimeLeave as any instanceof Date))
                        endTimeToLeave = (formData.EndTimeLeave as unknown as Date);
                    else
                        endTimeToLeave = parseTime(formData.EndTimeLeave);
            
            const AbsenceRequest = {
                ActivityType: { Id: parseInt(formData.AbsenceTypeId) },
                DateFrom: addMinutes((formData?.StartDateLeave as Date), (formData?.StartDateLeave as Date).getTimezoneOffset() * -1),//Ensure the localtime is not offseted because we need the date only 
                DateTo: addMinutes((formData?.EndDateLeave as Date), (formData?.EndDateLeave as Date).getTimezoneOffset() * -1),//Ensure the localtime is not offseted because we need the date only
                Period: parseInt(formData.PeriodTypeEnum),
                TimeFrom: startTimeToLeave ? addMinutes(startTimeToLeave, startTimeToLeave.getTimezoneOffset() * -1)  : null,
                TimeTo: endTimeToLeave ? addMinutes(endTimeToLeave, endTimeToLeave.getTimezoneOffset() * -1) : null,
                ApplicantRemark: formData.Remark,
            }

            AbsenceRequestForm.AbsenceRequest = AbsenceRequest;

            let httpCmd: RequestInit | null = null;
            await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                httpCmd = httpHeader('POST', token);
            });

            if (httpCmd) {
                let bodyRq = JSON.stringify(AbsenceRequestForm);
                (httpCmd as RequestInit).body = bodyRq;

                //console.log(bodyRq);

                var res = await fetch(getApiUrl((window as any).Config, 'hrportal') + '/absence/request', httpCmd);

                var text = await handleFetchErrors(res).text();
                //console.log(text);

                if (text.length > 0) {
                    console.log(text)
                }
            }
            setFormData(formDataInitialState);
            setFormSended(true);
            setErrorSendForm(false);
            //console.log(formData);
        }
        catch (e) {
            console.log(e);
            setLastException(e as unknown as string);
            setErrorSendForm(true);
        }
    }

    if (!checkModuleActive('Absences')) //Hidden module in config
        return (<></>);

    return (<React.Fragment>
        {(!formSended) && (
            <React.Fragment>
                <Typography variant="h6" className={classes.title}>{t("NewAbsenceRequest")}</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                            <FormControl variant="outlined" className={clsx(classes.formControl)}>
                                <InputLabel id="contract-select-outlined-label" >{t("ActivityType")}</InputLabel>
                                <Select
                                    labelId="contract-select-outlined-label"
                                    id="contract-select-select-outlined"
                                    value={formData.AbsenceTypeId}
                                    onChange={handleAbsenceTypes}
                                    label={t("ActivityType")}
                                    error={formState.ActivityTypeError}
                                    displayEmpty
                                >
                                    {
                                        absenceTypes.map((actType: any) => {
                                            if ('id' in actType)
                                                return (<MenuItem key={actType.id} value={actType.id}>{actType.text}</MenuItem>);
                                            else
                                                return null;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                            {IsInputDateSupported && (
                                <FormControl variant="outlined" className={clsx(classes.formControl)}>
                                    <TextField
                                        error={formState.StartDateLeaveError}
                                        id="start-datetime-holiday-local"
                                        label={t("StartDate")}
                                        type="date-local"
                                        defaultValue={formData.StartDateLeave}
                                        variant="outlined"
                                        onChange={handleFormChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            name: 'StartDateLeave',
                                            id: 'start-datetime-leave-label-placeholder',
                                        }}
                                    />
                                </FormControl>)
                            }
                            {(!IsInputDateSupported) && (
                                <KeyboardDatePicker className={clsx(classes.formControl)}
                                    margin="normal"
                                    label={t("StartDate")}
                                    error={formState.StartDateLeaveError}
                                    inputVariant="outlined"
                                    format="dd.MM.yyyy"
                                    value={formData.StartDateLeave}
                                    onChange={(sel) => handleDateChange(sel, "StartDateLeave")}
                                    KeyboardButtonProps={{
                                        'aria-label': t("StartDate"),
                                    }}
                                />)}
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                            {IsInputDateSupported && (
                                <FormControl variant="outlined" className={clsx(classes.formControl)}>
                                    <TextField
                                        error={formState.EndDateLeaveError}
                                        id="end-datetime-holiday-local"
                                        label={t("EndDate")}
                                        type="date-local"
                                        defaultValue={formData.EndDateLeave}
                                        variant="outlined"
                                        onChange={handleFormChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            name: 'EndDateLeave',
                                            id: 'end-datetime-leave-label-placeholder',
                                        }}
                                    />
                                </FormControl>)
                            }
                            {(!IsInputDateSupported) && (
                                <KeyboardDatePicker className={clsx(classes.formControl)}
                                    margin="normal"
                                    label={t("EndDate")}
                                    error={formState.EndDateLeaveError}
                                    inputVariant="outlined"
                                    format="dd.MM.yyyy"
                                    value={formData.EndDateLeave}
                                    onChange={(sel) => handleDateChange(sel, "EndDateLeave")}
                                    KeyboardButtonProps={{
                                        'aria-label': t("EndDate"),
                                    }}
                                />)}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                            <RadioGroup
                                name="PeriodTypeEnum"
                                value={formData.PeriodTypeEnum}
                                id="period-typeenum-local"
                                onChange={handleFormChange}
                                className={classes.indent}
                            >
                                <FormControlLabel value="1" control={<Radio />} label={t("AllDay")} />
                                <FormControlLabel value="2" control={<Radio />} label={t("Morning")} />
                                <FormControlLabel value="3" control={<Radio />} label={t("Afternoon")} />
                                <FormControlLabel value="4" control={<Radio />} label={t("TimeSlot")} />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                            {IsInputTimeSupported && (
                                <FormControl variant="outlined" className={clsx(classes.formControl, classes.indent2)}>
                                    <TextField
                                        id="time-starttime-leave"
                                        label={t("StartTime")}
                                        type="time"
                                        variant="outlined"
                                        error={formState.StartTimeLeaveError}
                                        defaultValue={formData.StartTimeLeave}
                                        disabled={formPeriodTypeEnumDisabled}
                                        onChange={handleFormChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                            name: 'StartTimeLeave',
                                        }}
                                    />
                                </FormControl>)}
                            {(!IsInputTimeSupported) && (
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="starttime-picker"
                                    label={t("StartTime")}
                                    error={formState.StartTimeLeaveError}
                                    inputVariant="outlined"
                                    value={formData.StartTimeLeave}
                                    disabled={formPeriodTypeEnumDisabled}
                                    className={classes.indent2}
                                    ampm={false}
                                    onChange={(sel) => handleDateChange(sel, "StartTimeLeave")}
                                    KeyboardButtonProps={{
                                        'aria-label': t("StartTime"),
                                    }}
                                />)}
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                            {IsInputTimeSupported && (
                                <FormControl variant="outlined" className={clsx(classes.formControl, classes.indent2)}>
                                    <TextField
                                        id="time-endtime-leave"
                                        label={t("EndTime")}
                                        type="time"
                                        variant="outlined"
                                        error={formState.EndTimeLeaveError}
                                        defaultValue={formData.EndTimeLeave}
                                        disabled={formPeriodTypeEnumDisabled}
                                        onChange={handleFormChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                            name: 'EndTimeLeave',
                                        }}
                                    />
                                </FormControl>)}
                            {(!IsInputTimeSupported) && (
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="endtime-picker"
                                    label={t("EndTime")}
                                    error={formState.EndTimeLeaveError}
                                    inputVariant="outlined"
                                    value={formData.EndTimeLeave}
                                    disabled={formPeriodTypeEnumDisabled}
                                    className={classes.indent2}
                                    ampm={false}
                                    onChange={(sel) => handleDateChange(sel, "EndTimeLeave")}
                                    KeyboardButtonProps={{
                                        'aria-label': t("EndTime"),
                                    }}
                                />)}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
                            <FormControl variant="outlined" className={clsx(classes.formControl)}>
                                <TextField
                                    id="civil-Remark-local"
                                    label={t("Remark")}
                                    variant="outlined"
                                    onChange={handleFormChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={formData.Remark}
                                    inputProps={{
                                        name: 'Remark',
                                        id: 'remark-label-placeholder',
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                            <Button variant="contained" disabled={!formValid} onClick={() => sendRequest()}>{t("SendRequest")}</Button>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                            <Collapse in={!formValid}>
                                <Alert severity="error">
                                    {t("VerifyYourEntry")}
                                </Alert>
                            </Collapse>
                            <Collapse in={errorSendForm}>
                                <Alert severity="error">
                                    {t("VerifyYourEntry")}
                                </Alert>
                                <Alert severity="error" onClick={() => alert('LastException: ' + lastException)}>
                                    {t("FromSendError")}
                                </Alert>
                            </Collapse>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </React.Fragment>
        )}
        {
            (formSended) && (
                <CardContent>
                    <Typography variant="h6" className={classes.title}>{t("RequestSended")}</Typography>
                    <Button variant="contained" onClick={() => setFormSended(false)}>{t("NewRequest")}</Button>
                </CardContent>
            )
        }
    </React.Fragment>
    );
}