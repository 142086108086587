import * as React from 'react';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { CardContent, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyles } from '../../common/styles';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from "@auth0/auth0-react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import {
    Link,
    useRouteMatch,
    useHistory,
    useLocation
} from "react-router-dom";
import AbsenceRequestsPlanning from './AbsenceRequestsPlanning';
import ValidatableAbsenceRequests from './ValidatableAbsenceRequests';
import { getBaseUrl, convertToAuth0Language, checkModuleActive } from '../../common/utils';
import { SessionContext } from '../../App';
import { sprintf } from 'sprintf-js';
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div style={{ margin: '20px' }}>{children}</div>
            )}
        </div>
    );
}

export default function AbsenceRequestManager() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const match = useRouteMatch();
    const history = useHistory();
    const theme = useTheme();

    const [value, setValue] = React.useState('0');

    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;
    const [awaitingValidationCount, setAwaitingValidationCount] = React.useState(0);

    const allTabs = [`${match.path}/validatableabsencerequests`, `${match.path}/absencerequestsplanning`];

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const tabAwaitingValidationProps = {
        label: sprintf(isSmallScreen ? t("ToValidate") : t("AwaitingValidation"), awaitingValidationCount)
    };

    const handleChange = React.useCallback(async (event: React.ChangeEvent<{}>, newValue: string) => {
        if (isAuthenticated) {
            //setValue(newValue);
            history.push(allTabs[parseInt(newValue)]);
        }
    }, [isAuthenticated]);

    function a11yProps(index: any) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    const location = useLocation();

    React.useEffect(() => {
        let tab = allTabs.indexOf(window.location.pathname);

        if (tab >= 0)
            setValue(tab.toString());
    }, [location]);

    React.useEffect(() => {//when logged in
        if (isAuthenticated)
            setAwaitingValidationCount(state.PendingAbsenceValidation);
        else
            loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
    }, [isAuthenticated, state]);

    if (!checkModuleActive('Absences')) //Hidden module in config
        return (<></>);

    return (<Container className={classes.centeredTopContainer}>
        <Card className={classes.card}>
            <CardContent>
                <Typography><FontAwesomeIcon icon="umbrella-beach" size="2x" fixedWidth className={classes.icons} /><span className={classes.pageTitleText}>{t("TimeOff")}</span></Typography>
                <Divider className={classes.pageTitleDivider} />
            </CardContent>
            <TabContext value={value}>
                <Paper className={classes.flexGrow}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab icon={<FontAwesomeIcon icon="chevron-left" />} onClick={() => { history.push(getBaseUrl() + '/absences/newabsencerequest'); }} />
                        <Tab {...tabAwaitingValidationProps} value="0" icon={<FontAwesomeIcon icon="thumbs-up" />} component={Link} to={allTabs[0]} {...a11yProps(0)} />
                        <Tab label={t("Schedule")} value="1" icon={<FontAwesomeIcon icon="calendar-alt" />} component={Link} to={allTabs[1]} {...a11yProps(1)} />

                    </Tabs>
                </Paper>
                <TabPanel value={value} index="0">
                    <ValidatableAbsenceRequests />
                </TabPanel>
                <TabPanel value={value} index="1">
                    <AbsenceRequestsPlanning />
                </TabPanel>
            </TabContext>
        </Card>
    </Container>
    );
}