/// <reference path = "../common/globaldef.d.ts" />
import * as React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme, withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton';
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Container } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { httpHeader, handleFetchErrors, getApiUrl, getBaseUrl, emptySession, convertToAuth0Language, checkModuleActive, getAppName } from '../common/utils';
import { useTranslation } from 'react-i18next';
import { SessionContext } from './../App';
import { sprintf } from 'sprintf-js';
import jwt_decode from "jwt-decode";

const drawerWidth = 260;
const footerHeight = 64;
const toolbarHeight = 64;


const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            minHeight: '100%',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
            },
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: '100vw',
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
        drawerClose: {
            overflowX: 'hidden',
            width: 0,
            margin: 0,
            padding: 0,
            borderRight: 0,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
        },
        toolbar: {
            display: 'flex',
            //flex: 0 1 auto;
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
                padding: 0
            },
        },
        userName: {
            fontSize: '1em',
            marginRight: '0.7em',
            display: 'inline',
            height: '100%',
            textAlign: 'center',
            cursor: 'pointer'
        },
        userIcon: {
            height: '100%',
            textAlign: 'center',
            cursor: 'pointer'
        },
        userAvatar: {
            backgroundColor: '#00505B',
            display: 'inline-flex',
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        logo: {
            height: (window as any).Config.Logo.Height,
            width: (window as any).Config.Logo.Width,
            marginRight: '0.7em',
        },
        topContainerHide: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            }
        },
        primaryText: {
            color: theme.palette.text.primary
        },
        footer: {
            display: 'flex',
            height: footerHeight + 'px',
            backgroundColor: theme.palette.text.primary,
            bottom: 0,
            width: '100%',
            color: theme.palette.grey[100],
            justifyContent: 'center',
            alignItems: 'center'
        },
        subcontent: {
            padding: theme.spacing(0),
            [theme.breakpoints.up('sm')]: {
                height: `calc(100% - ${toolbarHeight + footerHeight}px)`,
                padding: theme.spacing(3),
            },
            [theme.breakpoints.only('xs')]: {
                height: `calc(100% - ${toolbarHeight - 8 + footerHeight}px)`,
            }
        },
        background: {
            height: '100%',
            backgroundImage: `url("${process.env.PUBLIC_URL}hrhome.jpg")`,
            opacity: '0.85',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        watermark: {
            color: '#d0d0d0d0',
            fontSize: '12vmin',
            transform: 'rotate(-45deg)',
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            margin: '0',
            zIndex: 1,
            left: '20%',
            top: '40%',
            pointerEvents: 'none'
        }
    }), { index: 1 }  //  optional argument for JSS, to set position after MUI stylesheets
);


export default function NavMenu(props: { children?: React.ReactNode }) {
    const { isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
    const { t } = useTranslation();
    const [userName, setUserName] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [openMenuAbsence, setOpenMenuAbsence] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const SessionContextConsumer = React.useContext(SessionContext);
    const { dispatch, state } = SessionContextConsumer;
    const [awaitingValidationCount, setAwaitingValidationCount] = React.useState(0);

    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();

    window.onscroll = () => { };

    React.useEffect(() => {
        let source = document.getElementById("topNavMenu");
        let destination = document.getElementById("topLevelContainer");
        if (source && destination && destination.style)
            destination.style.marginTop = source.offsetHeight + 'px';
    });

    React.useEffect(() => {
        //console.log("Use Effect 2")
        async function fetchAPIForSession() {

            if (isAuthenticated) {
                let httpCmd = null;
                let session: SessionState = emptySession(); // { AddressId: 0, ContractId: 0, FirstName: '', LastName: '', EmployeeNo: '', PendingAbsenceRequests: 0, PendingAbsenceValidation: 0, IsAbsenceEnabled: false, IsValidateManager: false };
                await getAccessTokenSilently({ audience: (window as any).Config.Auth0Audience, scope: 'openid profile email', ignoreCache: false }).then(token => {
                    httpCmd = httpHeader('GET', token);
                    //FIND THE EXTRA RIGHTS HERE
                    var decoded = jwt_decode(token);

                    if ((decoded as any).permissions)
                        if ((decoded as any).permissions.indexOf('validate:manager') >= 0)
                            session.IsValidateManager = true;

                }).catch(e => { console.log(e); });

                if (httpCmd) {
                    await fetch(getApiUrl((window as any).Config, 'hrportal') + '/currentemployee/address', httpCmd)
                        .then(res => {
                            //handleFetchErrors(res).json()
                            //console.log(res);

                            if (res.status === 401) {
                                logout();
                                //window.location.href = getBaseUrl() + '/';
                            }

                            if (res.status === 200) {
                                res.json().then(ret => {
                                    session.AddressId = ret.addressId;
                                    session.FirstName = ret.firstName;
                                    session.LastName = ret.lastName;
                                }).catch(e => console.log(e));
                            }
                            else
                                throw Error(res.statusText);
                        })
                        .catch(e => console.log(e));

                    await fetch(getApiUrl((window as any).Config, 'hrportal') + '/currentemployee/contracts', httpCmd)
                        .then(res => handleFetchErrors(res).json())
                        .then(ret => {
                            var selected = ret.contracts.filter((c: any) => c.hrPortalDefault);

                            if (Array.isArray(selected) && selected.length > 0) {
                                session.ContractId = selected[0].id;
                                session.EmployeeNo = selected[0].contractNumber;
                                session.IsAbsenceEnabled = true;
                            }
                            else
                                if (Array.isArray(ret.contracts) && ret.contracts.length > 0) {
                                    session.ContractId = ret.contracts[0].id;
                                    session.EmployeeNo = selected[0].contractNumber;
                                    session.IsAbsenceEnabled = true;
                                }

                            
                        }).catch(e => console.log(e));

                    if (session.AddressId > 0 && session.ContractId > 0)
                        await fetch(getApiUrl((window as any).Config, 'hrportal') + '/absence/requestscounts?addressid=' + session.AddressId + '&contractid=' + session.ContractId, httpCmd)
                            .then(res => handleFetchErrors(res).json())
                            .then(ret => {
                                session.PendingAbsenceRequests = ret.pendingAbsenceRequests;
                                session.PendingAbsenceValidation = ret.pendingAbsenceValidation;
                                setAwaitingValidationCount(session.PendingAbsenceValidation);
                            }).catch(e => console.log(e));

                    if (session.AddressId > 0 && session.ContractId > 0) {
                        //console.log("dispatch session value")
                        //console.log(session)

                        dispatch({ type: 'state', data: session } as SessionAction);
                    }
                    else {
                        //console.log(session);
                        throw new Error('Can create session. either the API is unreachable or address/contract wasn\'t found for this user.');
                    }
                } else {
                    console.log('loginWithRedirect')
                    loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL });
                }
            };
        };
        fetchAPIForSession();

    }, [isAuthenticated]);

    React.useEffect(() => {
        if (isAuthenticated && state.LastName && state.LastName.trim().length > 0 && state.FirstName && state.FirstName.trim().length > 0)
            setUserName(state.FirstName + ' ' + state.LastName);

    }, [isAuthenticated, state]);

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (Boolean(anchorEl))
            setAnchorEl(null);
        else
            setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <div style={{ height: '100%' }}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <Grid justify="space-between" alignItems="center" container>
                            <Grid item>
                                <div style={{ display: 'inline-flex', height: '100%', alignItems: 'center', }}>
                                    {isAuthenticated && (
                                        <React.Fragment>
                                            <Hidden smUp implementation="css">
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    onClick={handleDrawerOpen}
                                                    edge="start"
                                                    className={classes.menuButton}
                                                >
                                                    <MenuIcon />
                                                </IconButton>
                                            </Hidden>
                                            <Hidden xsDown implementation="css">
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    onClick={handleDrawerOpen}
                                                    edge="start"
                                                    className={clsx(classes.menuButton, {
                                                        [classes.hide]: open,
                                                    })}
                                                >
                                                    <MenuIcon />
                                                </IconButton>
                                            </Hidden>
                                        </React.Fragment>
                                    )
                                    }
                                </div>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <img src={(window as any).Config.Logo.File} className={classes.logo} alt="logo" onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/'); }} />
                                    <Hidden xsDown><Typography color="inherit" variant="h6">
                                        {getAppName()}
                                    </Typography></Hidden>
                                    <Hidden smUp><Typography color="inherit">
                                        {getAppName()}
                                    </Typography></Hidden>
                                </div>
                            </Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    {isAuthenticated && (
                                        <Container onClick={handleClick}>
                                            <Hidden xsDown><Typography className={classes.userName}>{userName}</Typography></Hidden>
                                            <FontAwesomeIcon className={classes.userIcon} size="2x" icon="user" />
                                            <Menu
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + "/myaccount"); }}>{t("MyAccount")}</MenuItem>
                                                <MenuItem onClick={() => logout({ returnTo: window.location.origin + process.env.PUBLIC_URL })}>{t("Logout")}</MenuItem>
                                            </Menu>
                                        </Container>
                                    )
                                    }
                                    {!isAuthenticated && (
                                        <Button variant="contained" color="default" size="medium" startIcon={<FontAwesomeIcon icon="sign-in-alt" />}
                                            onClick={() => { loginWithRedirect({ ui_locales: convertToAuth0Language(''), redirectUri: window.location.origin + process.env.PUBLIC_URL }); }}>
                                            Login
                                        </Button>)
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                {(process.env.PUBLIC_URL == '/test') && (
                    <div className={classes.watermark}>Test Version</div>
                )}
                {isAuthenticated && (
                    <div className={classes.root}>
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            })}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: open,
                                    [classes.drawerClose]: !open,
                                }),
                            }}
                        >
                            <div className={classes.toolbar}>
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === 'rtl' ? <ChevronRightIcon className={classes.primaryText} /> : <ChevronLeftIcon className={classes.primaryText} />}
                                </IconButton>
                            </div>
                            <Divider />
                            <List>
                                <LightTooltip title={t("Home")} placement="right" disableHoverListener={open}>
                                    <ListItem button key={t("Home")} onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/'); }}>
                                        <ListItemIcon className={classes.primaryText}><FontAwesomeIcon icon="home" size="2x" fixedWidth /></ListItemIcon>
                                        <ListItemText primary={t("Home")} className={classes.primaryText} />
                                    </ListItem>
                                </LightTooltip>
                                {checkModuleActive('News') && (
                                    <LightTooltip title={t("News")} placement="right" disableHoverListener={open}>
                                        <ListItem button key={t("News")} onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/news'); }}>
                                            <ListItemIcon className={classes.primaryText}><FontAwesomeIcon icon="newspaper" size="2x" /></ListItemIcon>
                                            <ListItemText primary={t("News")} className={classes.primaryText} />
                                        </ListItem>
                                    </LightTooltip>
                                )}
                                {checkModuleActive('TimeBalance') && (
                                <LightTooltip title={t("HoursBalance")} placement="right" disableHoverListener={open}>
                                    <ListItem button key={t("HoursBalance")} onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/timebalance'); }}>
                                        <ListItemIcon className={classes.primaryText}><FontAwesomeIcon icon="user-clock" size="2x" fixedWidth /></ListItemIcon>
                                        <ListItemText primary={t("HoursBalance")} className={classes.primaryText} />
                                    </ListItem>
                                </LightTooltip>
                                )}
                                {checkModuleActive('HRDocuments') && (
                                <LightTooltip title={t("HRDocuments")} placement="right" disableHoverListener={open}>
                                    <ListItem button key={t("HRDocuments")} onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/hrdocument'); }}>
                                        <ListItemIcon className={classes.primaryText}><FontAwesomeIcon icon="file-alt" size="2x" fixedWidth /></ListItemIcon>
                                        <ListItemText primary={t("HRDocuments")} className={classes.primaryText} />
                                    </ListItem>
                                </LightTooltip>
                                )}
                                {checkModuleActive('SalaryDocuments') && (
                                <LightTooltip title={t("SalaryDocument")} placement="right" disableHoverListener={open}>
                                    <ListItem button key={t("SalaryDocument")} onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/salarydocument'); }}>
                                        <ListItemIcon className={classes.primaryText}><FontAwesomeIcon icon="search-dollar" size="2x" fixedWidth /></ListItemIcon>
                                        <ListItemText primary={t("SalaryDocument")} className={classes.primaryText} />
                                    </ListItem>
                                </LightTooltip>
                                )}
                                {checkModuleActive('PersonalDocuments') && (
                               <LightTooltip title={t("PersonalDocuments")} placement="right" disableHoverListener={open}>
                                    <ListItem button key={t("PersonalDocuments")} onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/personaldocument'); }}>
                                        <ListItemIcon className={classes.primaryText}><FontAwesomeIcon icon="user-lock" size="2x" fixedWidth /></ListItemIcon>
                                        <ListItemText primary={t("PersonalDocuments")} className={classes.primaryText} />
                                    </ListItem>
                                </LightTooltip>
                                )}
                                {checkModuleActive('Absences') && (<>
                                <LightTooltip title={t("TimeOff")} placement="right" disableHoverListener={open}>
                                    <ListItem button key={t("TimeOff")} onClick={() => { setOpenMenuAbsence(!openMenuAbsence) }}>
                                        <ListItemIcon className={classes.primaryText}><FontAwesomeIcon icon="umbrella-beach" size="2x" fixedWidth /></ListItemIcon>
                                        <ListItemText primary={t("TimeOff")} className={classes.primaryText} />
                                        {openMenuAbsence ? <IconExpandLess /> : <IconExpandMore />}
                                    </ListItem>
                                </LightTooltip>
                                <Collapse in={openMenuAbsence} timeout="auto" unmountOnExit>
                                    <Divider />
                                    <List component="div" disablePadding>
                                        <LightTooltip title={t("AbsenceNewRequest")} placement="right" disableHoverListener={open}>
                                            <ListItem button className={classes.primaryText} onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/absences/newabsencerequest'); }}>
                                                <ListItemIcon className={classes.primaryText}><FontAwesomeIcon icon="calendar-plus" size="lg" fixedWidth pull="right" /></ListItemIcon>
                                                <ListItemText primary={t("AbsenceNewRequest")} />
                                            </ListItem>
                                        </LightTooltip>
                                        <LightTooltip title={t("AbsenceCurrent")} placement="right" disableHoverListener={open}>
                                            <ListItem button className={classes.primaryText} onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/absences/currentabsencerequests'); }}>
                                                <ListItemIcon className={classes.primaryText}><FontAwesomeIcon icon="calendar-check" size="lg" fixedWidth pull="right" /></ListItemIcon>
                                                <ListItemText primary={t("AbsenceCurrent")} />
                                            </ListItem>
                                        </LightTooltip>
                                        <LightTooltip title={t("History")} placement="right" disableHoverListener={open}>
                                            <ListItem button className={classes.primaryText} onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/absences/absencerequesthistory'); }}>
                                                <ListItemIcon className={classes.primaryText}><FontAwesomeIcon icon="history" size="lg" fixedWidth pull="right" /></ListItemIcon>
                                                <ListItemText primary={t("History")} />
                                            </ListItem>
                                        </LightTooltip>
                                        {
                                            (state.IsValidateManager) && (
                                                <React.Fragment>
                                                    <LightTooltip title={sprintf(t("AwaitingValidation"), awaitingValidationCount)} placement="right" disableHoverListener={open}>
                                                        <ListItem button className={classes.primaryText} onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/absencesvalidate/validatableabsencerequests'); }}>
                                                            <ListItemIcon className={classes.primaryText}><Badge color="secondary" badgeContent={awaitingValidationCount}><FontAwesomeIcon icon="thumbs-up" size="lg" fixedWidth pull="right" /></Badge></ListItemIcon>
                                                            <ListItemText primary={sprintf(t("AwaitingValid"), awaitingValidationCount)} />
                                                        </ListItem>
                                                    </LightTooltip>
                                                    <LightTooltip title={t("Schedule")} placement="right" disableHoverListener={open}>
                                                        <ListItem button className={classes.primaryText} onClick={() => { handleDrawerClose(); history.push(getBaseUrl() + '/absencesvalidate/absencerequestsplanning'); }}>
                                                            <ListItemIcon className={classes.primaryText}><FontAwesomeIcon icon="calendar-alt" size="lg" fixedWidth pull="right" /></ListItemIcon>
                                                            <ListItemText primary={t("Schedule")} />
                                                        </ListItem>
                                                    </LightTooltip>
                                                </React.Fragment>
                                            )
                                        }
                                    </List>
                                    </Collapse></>
                                )}
                            </List>
                            <Divider />
                        </Drawer>
                        <main className={clsx(classes.content, {
                            [classes.topContainerHide]: open,
                        })}>
                            <div className={classes.toolbar} />
                            <Container id="topLevelContainer" className={clsx(classes.subcontent, {
                                [classes.topContainerHide]: open,
                            })}>
                                {props.children}
                            </Container>
                            <div className={classes.footer}>
                                {t("MainFooterText1")}<Hidden xsDown>{t("MainFooterText2")}</Hidden>
                            </div>
                        </main>
                    </div>
                )}
                {
                    (!isAuthenticated) && (
                        <div className={classes.root}>
                            <div className={classes.content}>
                                <div style={{ marginTop: `${toolbarHeight}px`, height: `calc(100% - ${toolbarHeight + footerHeight}px)`, textAlign: 'center' }}>
                                    <div className={classes.background}></div>
                                </div>
                                <div className={classes.footer}>
                                    {t("MainFooterText1")}<Hidden xsDown>{t("MainFooterText2")}</Hidden>
                                </div>
                            </div>
                        </div>
                    )}

            </div>
        </React.Fragment>
    );
}
